import React, { useEffect } from 'react';
import './styles/MainStyle.css'
import './styles/AiDeLStyle.css';
import './styles/SIL_Images.css';
import Axios from 'axios';
import {useState} from 'react';
import TargetGroß from './components/TargetGroß';
import World from './components/World';
import Tutor3 from './components/Tutor3';
import Stimulus from './components/Stimulus';
import Button from './components/Button';
import TargetKlein from './components/TargetKlein';
import abschluss from './assets/audio/AbschlussD.mp3';
import timeSound from './assets/audio/Time.mp3';
import stimSound from './assets/audio/Stimulus.mp3';
import targetSound from './assets/audio/TargetD.mp3';
import mod1 from './assets/audio/Moderation1D.mp3';
import mod2 from './assets/audio/Moderation2D.mp3';
import mod3 from './assets/audio/Moderation3D.mp3';
import mod4 from './assets/audio/Moderation4D.mp3';
import mod6 from './assets/audio/Moderation6D.mp3';
import mod7 from './assets/audio/Moderation7D.mp3';
import mod8 from './assets/audio/Moderation8D.mp3';
import mod9 from './assets/audio/Moderation9D.mp3';
import mod10 from './assets/audio/Moderation10D.mp3';
import mod11 from './assets/audio/Moderation11D.mp3';
import mod12 from './assets/audio/Moderation12D.mp3';
import mod13 from './assets/audio/Moderation13D.mp3';
import buttonSound from './assets/audio/Button.mp3';
import modRe1 from './assets/audio/RetestModeration1.mp3'
import l1 from './assets/audio/L1D.mp3';
import l2 from './assets/audio/L2D.mp3';
import l3 from './assets/audio/L3D.mp3';
import l4 from './assets/audio/L4D.mp3';
import l5 from './assets/audio/L5D.mp3';
import l6 from './assets/audio/L6D.mp3';
import l7 from './assets/audio/L7D.mp3';
import l8 from './assets/audio/L8D.mp3';
import l1m from './assets/audio/L1M.mp3';
import l2m from './assets/audio/L2M.mp3';
import l1s from './assets/audio/L1S.mp3';
import l2s from './assets/audio/L2S.mp3';
import E_Entenerpel from './assets/audio/E_Entenerpel.mp3';
import E_Feigenkerne from './assets/audio/E_Feigenkerne.mp3';
import E_downloaden from './assets/audio/E_downloaden.mp3';
import E_geliket from './assets/audio/E_geliket.mp3';
import E_gelogen from './assets/audio/E_gelogen.mp3';
import E_Notenhefte from './assets/audio/E_Notenhefte.mp3';
import E_Pudelbeine from './assets/audio/E_Pudelbeine.mp3';
import E_verbogen from './assets/audio/E_verbogen.mp3';
import p2_tut1 from './assets/audio/Phase2_Tut1.mp3';
import p2_tut2 from './assets/audio/Phase2_Tut2.mp3';
import p2_tut3 from './assets/audio/Phase2_Tut3.mp3';
import p2_tut4 from './assets/audio/Phase2_Tut4.mp3';
import p2_tut5 from './assets/audio/Phase2_Tut5.mp3';
import p3_tut4 from './assets/audio/Phase3_Tut4.mp3';
import p3_tut5 from './assets/audio/Phase3_Tut5.mp3';
import p4_tut2 from './assets/audio/Phase4_Tut2.mp3';
import aud_meta1 from './assets/audio/Meta1.mp3';
import aud_meta2 from './assets/audio/Meta2.mp3';
import aud_meta3 from './assets/audio/Meta3.mp3';
import aud_meta4 from './assets/audio/Meta4.mp3';
import aud_meta5 from './assets/audio/Meta5.mp3';
import newTut2_1 from './assets/audio/NewTut2_1.mp3';
import aud_Tom from './assets/audio/Tom.mp3';


import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import useSound from 'use-sound';



function AiDeL4() {

    disableBodyScroll(document);
    const version = "10.1.1d";
    const connect = "online";
    const queryParams = new URLSearchParams(window.location.search)

    {/*Variablen Meta*/ }
    const [itemAnzahlPhase1, setItemAnzahlPhase1] = useState(0);
    const [itemAnzahlPhase2, setItemAnzahlPhase2] = useState(0);
    const [itemAnzahlPhase3, setItemAnzahlPhase3] = useState(0);
    const [itemAnzahlPhase4, setItemAnzahlPhase4] = useState(0);
    const [kurzVersion, setKurzVersion] = useState(false);
    const[Api, setApi] = useState("http://192.168.8.100:5000/");
    {/*Variablen Meta*/ }

    {/*Variablen Login*/ }
    const userCode = queryParams.get('userCode');
    const schulerCode = queryParams.get('schulerCode')
    const [testTicket, setTestTicket] = useState();
    const [transferTicket, setTransferTicket] = useState(0);
    const schulerKlasse = parseInt(queryParams.get('Klassenstufe'));
    const KlassenName = queryParams.get('Klassenname')
    const [Kindername, setKindername] = useState("Max Mustermann");
    const [testbogen, setTestbogen] = useState("AiDe-L-M1");
    const [reTest, setReTest] = useState("false");
    const [modus, setModus] = useState("aide");
    const [rechtsy, setRechtsy] = useState(true);
    const [itemsDa, setItemsDa] = useState(false);
    const [ticketMonitor, setTicketMonitor] = useState("TicketMonitor gone");
    const [repeatButton, setRepeatButton] = useState("RepeatButton gone"); 
    const [viewOfflineAdminseite, setViewOfflineAdminseite] = useState('OfflineAdminseite gone');
    const [viewAbschlussSeite, setViewAbschlussSeite] = useState('AbschlussSeite')
    const [AdminTool, setAdminTool] = useState("AdminToolgone");
    const [AdminToolPage, SetAdminToolPage] = useState("AdminToolPage gone");
    const [EndPanelText, setEndPanelText] = useState("Lade...");
    const [DemoModus, setDemoModus] = useState(false);
    const DemoParams = queryParams.get('Demo');
    const [End_Panel, setEnd_Panel] = useState('End_Panel');
    const [DemoPanel, setDemoPanel] = useState('DemoPanel_gone')
    {/*Variablen Login*/ }

  
    {/*Variablen Visuelles*/ }
    const[Szene, setSzene] = useState("startschreibtisch");
    const [LoginButton, setLoginButton] = useState('LoginButtonSD');
    const [StartButton, setStartButton] = useState('StartButton gone');
    const [DatenSeite, setDatenSeite] = useState('datenseite sichtbar');
    const [RechtshanderButtonaktiv, setRechtshanderButtonaktiv] = useState('RechtshanderButtonSD aktiv')
    const [LinkshanderButtonaktiv, setLinkshanderButtonaktiv] = useState('LinkshanderButtonSD inaktiv')
    const [SchulerCodeFeld, setSchulerCodeFeld] = useState('SchulerCodeFeld');
    const [LehrerCodeFeld, setLehrerCodeFeld] = useState('LehrerCodeFeldSD');
    const [SchulerKlassenFeld, setSchulerKlassenFeld] = useState('SchulerKlassenFeldSD');
    const [VerbleibendeTestungenFeld, setVerbleibendeTestungenFeld] = useState('VerbleibendeTestungenFeldSD');
    const [VersionsFeld, setVersionsFeld] = useState('VersionsFeldSD');
    const [TestbogenFeld, setTestbogenFeld] = useState('TestbogenFeldSD');
    const [HandigkeitFeld, setHandigkeitFeld] = useState('HandigkeitFeldSD');
    const [DiagnoseSeite, setDiagnoseSeite] = useState ('diagnoseseite gone');
    const [TargetVisible, setTargetVisible] = useState ('TargetWrapperGroß gone');
    const [ButtonBereich, setButtonBereich] = useState ('ButtonBereich');
    const [ButtonLinks, setButtonLinks] = useState('ButtonLinks gone');
    const [ButtonRechts, setButtonRechts] = useState('ButtonRechts gone');
    const [ButtonLinksType, setButtonLinksType] = useState('Inaktiv');
    const [ButtonRechtsType, setButtonRechtsType] = useState('Inaktiv');
    const [TargetKleinVisible, setTargetKleinVisible] = useState('TargetKleinWrapper gone');
    const [WeiterButton, setWeiterButton] = useState('WeiterButton gone');
    const [StimulusWrapper, setStimulusWrapper] = useState("StimulusWrapper gone");
    const [progress, setProgress] = useState(0.0);
    const [progressBarVisible, setProgressBarVisible] = useState("ProgressbarWrapper gone");
    const [theLightSide, setTheLightSide] = useState(255);
    const [theDarkSide, setTheDarkSide] = useState(0);
    const [mood, setMood] = useState(7);
    const [forceSteps, setForceSteps] = useState(0);
    const [TutorScreen, setTutorScreen] = useState('Android');
    const [TutorText, setTutorText] = useState('0');
    const [Theme, setTheme] = useState('SecretDucks');
    const [viewSpeaker, setViewSpeaker] = useState('TargetSpeaker_gone');
    const [Speaker_work, setSpeakerWork] = useState('SpeakerIcon_silent');
    const [help_reload, setHelp_reload] = useState(true);
    const [SatzItemLayout, setSatzItemLayout] = useState('SatzItemLayout_gone');
    const [SIL_Textbereich, setSIL_Textbereich] = useState('SIL_Textbereich_gone');
    const [SIL_Bildbereich_BildFeld1, setSIL_Bildbereich_BildFeld1] = useState('SIL_Bildbereich_BildFeld1_gone');
    const [SIL_Bildbereich_BildFeld2, setSIL_Bildbereich_BildFeld2] = useState('SIL_Bildbereich_BildFeld2_gone');
    const [SIL_Bildbereich_BildFeld3, setSIL_Bildbereich_BildFeld3] = useState('SIL_Bildbereich_BildFeld3_gone');
    const [SIL1, setSIL1] = useState();
    const [SIL2, setSIL2] = useState();
    const [SIL3, setSIL3] = useState();
    const [SIL_Counter1, set_SIL_Counter1] = useState(0);
    const [SIL_Counter2, set_SIL_Counter2] = useState(0);
    const [End_Panel_Fill, set_End_Panel_Fill] = useState("End_Panel_Loading");
    const [End_Panel_EndButton, setEnd_Panel_EndButton] = useState("End_Panel_EndButton_gone");
    {/*Variablen Visuelles*/ }

    {/*Variablen Testung*/ }
    const [TargetText, setTargetText] = useState('Entenerpel');
    const [TargetFont, setTargetFont] = useState('Fibel');
    const [TargetType, setTargetType] = useState('Text');
    const [inTutorium, setInTutorium] = useState(false);
    const [inDiagnose, setInDiagnose] = useState(false);
    const [inDiagnose2, setInDiagnose2] = useState(false);
    const [tutCounter, setTutCounter] = useState(0); 
    const [buttonAktiv, setButtonAktiv] = useState(false);
    const [RetestButtonJa, setRetestbuttonJa] = useState('LinkshanderButton aktiv');
    const [RetestButtonNein, setRetestbuttonNein] = useState('RechtshanderButton inaktiv');
    const [StimulusText, setStimulusText] = useState('');
    const [StimulusType, setStimulusType] = useState('Fibel');
    const [cycleCounter1, setCycleCounter1] = useState(0);
    const [cycleCounter2, setCycleCounter2] = useState(0);
    const [stimulusCounter1, setStimulusCounter1] = useState(0);
    const [stimulusCounter2, setStimulusCounter2] = useState(0);
    const [slsCounter, setSlsCounter] = useState(0);
    const [stimulusOnsetTime, setStimulusOnsetTime] = useState(0);
    const [items, setItems] = useState([]);
    const [targetUsed, setTargetUsed] = useState([]);
    const [itemsUsed, setItemsUsed] = useState([]);
    const [targetOnsetTime, setTargetOnsetTime] = useState(0);
    const [targetDelta, setTargetDelta] = useState([]);
    const [s1Used, setS1Used] = useState([]);
    const [s2Used, setS2Used] = useState([]);
    const [s3Used, setS3Used] = useState([]);
    const [s4Used, setS4Used] = useState([]);
    const [s1Delta, setS1Delta] = useState([]);
    const [s2Delta, setS2Delta] = useState([]);
    const [s3Delta, setS3Delta] = useState([]);
    const [s4Delta, setS4Delta] = useState([]);
    const [s1Antwort, setS1Antwort] = useState([]);
    const [s2Antwort, setS2Antwort] = useState([]);
    const [s3Antwort, setS3Antwort] = useState([]);
    const [s4Antwort, setS4Antwort] = useState([]);
    const [anzahlRichtige, setAnzahlRichtige] = useState(0);
    const [startzeitPhase2, setStartzeitPhase2] = useState(0);
    const [itemsPre, setItemsPre] = useState([]);
    const [itemGruppe0, setItemGruppe0] = useState([]);
    const [itemGruppe1, setItemGruppe1] = useState([]);
    const [itemGruppe2, setItemGruppe2] = useState([]);
    const [itemGruppe3, setItemGruppe3] = useState([]);
    const [itemGruppe4, setItemGruppe4] = useState([]);
    const [hilfeWort, setHilfeWort] = useState(false);
    const [inHilfePhase, setInHilfePhase] = useState(false);
    const [e_oneshot, set_o_oneshot] = useState(false);
    const [Phase1, setPhase1] = useState(true);
    const [Phase2, setPhase2] = useState(false);
    const [Phase3, setPhase3] = useState(false);
    const [SIL_Target, set_SIL_Target] = useState("");
    const [SIL_Item_pre, setSIL_Item_pre] = useState([])
    const [SIL_Item_pre_pre, setSIL_Item_pre_pre] = useState([])
    const [SIL_Item, set_SIL_Item] = useState([]);
    const [SatzItemOnsetTime, setSatzItemOnsetTime] = useState(0);
    const [SIL_Delta, set_SIL_Delta] = useState([]);
    const [SIL_used, set_SIL_used] = useState([]);
    const [SIL_text_used, set_SIL_text] = useState([]);
    const [SIL_korrekt, set_SIL_korrekt] = useState([]); 
    const [SIL_Textbereich_Textfeld, setSIL_Textbereich_Textfeld] = useState('SIL_Textbereich_Textfeld');
    const [MetaSeite, setMetaSeite] = useState('MetaSeite_gone');
    const [MetaSeite_Seite1, setMetaSeite_Seite1] = useState('MetaSeite_Seite1');
    const [MetaSeite_Seite2, setMetaSeite_Seite2] = useState('MetaSeite_Seite2_gone');
    const [MetaSeite_Seite3, setMetaSeite_Seite3] = useState('MetaSeite_Seite3_gone');
    const [MetaSeite_Seite4, setMetaSeite_Seite4] = useState('MetaSeite_Seite4_gone');
    const [MetaSeite_Seite5, setMetaSeite_Seite5] = useState('MetaSeite_Seite5');
    const [MetaS1_Deutsch, set_MetaS1_Deutsch] = useState('Meta_S1_Flagge_gone');
    const [MetaS1_Andere, set_MetaS1_Andere] = useState('Meta_S1_Flagge_gone');

    const [MetaS2_R1, setMetaS2_R1] = useState('Meta_Seite2_Reihe_gone');
    const [MetaS2_R2, setMetaS2_R2] = useState('Meta_Seite2_Reihe_gone');
    const [MetaS2_R3, setMetaS2_R3] = useState('Meta_Seite2_Reihe_gone');
    const [MetaS2_R4, setMetaS2_R4] = useState('Meta_Seite2_Reihe_gone');
    const [MetaS2_R5, setMetaS2_R5] = useState('Meta_Seite2_Reihe_gone');
    const [MetaS2_R6, setMetaS2_R6] = useState('Meta_Seite2_Reihe_gone');
    const [MetaS2_R7, setMetaS2_R7] = useState('Meta_Seite2_Reihe_gone');
    const [MetaS2_R8, setMetaS2_R8] = useState('Meta_Seite2_Reihe_gone');

    const [MetaS3_B1, setMetaS3_B1] = useState('Meta_Seite2_Button_gone');
    const [MetaS3_B2, setMetaS3_B2] = useState('Meta_Seite2_Button_gone');
    const [MetaS3_B3, setMetaS3_B3] = useState('Meta_Seite2_Button_gone');
    const [MetaS3_B4, setMetaS3_B4] = useState('Meta_Seite2_Button_gone');

    const [MetaS4_B1, setMetaS4_B1] = useState('Meta_Seite4_Button_gone');
    const [MetaS4_B2, setMetaS4_B2] = useState('Meta_Seite4_Button_gone');
    const [MetaS4_B3, setMetaS4_B3] = useState('Meta_Seite4_Button_gone');
    const [MetaS4_B4, setMetaS4_B4] = useState('Meta_Seite4_Button_gone');

    const [MetaS5_B1, setMetaS5_B1] = useState('Meta_Seite5_Button_gone');
    const [MetaS5_B2, setMetaS5_B2] = useState('Meta_Seite5_Button_gone');


    const [MetaDa, setMetaDa] = useState(false);
    const [Meta_Handigkeit, set_Meta_Handigkeit] = useState('rechts');
    const [Meta_Familiensprache, set_Meta_Familiensprache] = useState('');
    const [Meta_Lesemedien, set_Meta_Lesemedien] = useState(0);
    const [Meta_Lesezeit, set_Meta_Lesezeit] = useState(0);
    const [Meta_Gender, set_Meta_Gender] = useState(99);
    {/*Variablen Testung*/ }
    {/*Variablen AUDIO*/ }
    //const [AudioTest] = useSound(aud_Tom);
    const [PlayAudio_mod1] =useSound(mod1);
    const [PlayAudio_mod2] =useSound(mod2);
    const [PlayAudio_mod3] =useSound(mod3);
    const [PlayAudio_mod4] =useSound(mod4);
    const [PlayAudio_mod6] =useSound(mod6);
    const [PlayAudio_mod7] =useSound(mod7);
    const [PlayAudio_mod8] =useSound(mod8);
    const [PlayAudio_mod9] =useSound(mod9);
    const [PlayAudio_mod13] =useSound(mod13);
    const [PlayAudio_buttonSound] =useSound(buttonSound);
    const [PlayAudio_modRe1] =useSound(modRe1);
    const [PlayAudio_l1] =useSound(l1);
    const [PlayAudio_l2] =useSound(l2);
    const [PlayAudio_l3] =useSound(l3);
    const [PlayAudio_l4] =useSound(l4);
    const [PlayAudio_l5] =useSound(l5);
    const [PlayAudio_l6] =useSound(l6);
    const [PlayAudio_l7] =useSound(l7);
    const [PlayAudio_l8] =useSound(l8);
    const [PlayAudio_l1m] =useSound(l1m);
    const [PlayAudio_l2m] =useSound(l2m);
    const [PlayAudio_l1s] =useSound(l1s);
    const [PlayAudio_l2s] =useSound(l2s);
    const [PlayAudio_E_Entenerpel] =useSound(E_Entenerpel);
    const [PlayAudio_E_Feigenkerne] =useSound(E_Feigenkerne);
    const [PlayAudio_E_downloaden] =useSound(E_downloaden);
    const [PlayAudio_E_geliket] =useSound(E_geliket);
    const [PlayAudio_E_gelogen] =useSound(E_gelogen);
    const [PlayAudio_E_Notenhefte] =useSound(E_Notenhefte);
    const [PlayAudio_E_Pudelbeine] =useSound(E_Pudelbeine);
    const [PlayAudio_E_verbogen] =useSound(E_verbogen);
    const [PlayAudio_p2_tut1] =useSound(p2_tut1);
    const [PlayAudio_p2_tut2] =useSound(p2_tut2);
    const [PlayAudio_p2_tut3] =useSound(p2_tut3);
    const [PlayAudio_p2_tut4] =useSound(p2_tut4);
    const [PlayAudio_p2_tut5] =useSound(p2_tut5);
    const [PlayAudio_p3_tut1] =useSound(mod10);
    const [PlayAudio_p3_tut2] =useSound(mod11);
    const [PlayAudio_p3_tut3] =useSound(mod12);
    const [PlayAudio_p3_tut4] =useSound(p3_tut4);
    const [PlayAudio_p3_tut5] =useSound(p3_tut5);
    const [PlayAudio_p4_tut1] =useSound(newTut2_1);
    const [PlayAudio_p4_tut2] =useSound(p4_tut2);
    const [PlayAudio_aud_meta1] =useSound(aud_meta1);
    const [PlayAudio_aud_meta2] =useSound(aud_meta2);
    const [PlayAudio_aud_meta3] =useSound(aud_meta3);
    const [PlayAudio_aud_meta4] =useSound(aud_meta4);
    const [PlayAudio_aud_meta5] =useSound(aud_meta5);
    const [PlayAudio_aud_Tom] =useSound(aud_Tom);

    const [PlayAudio_timeSound] = useSound(timeSound);
    const [PlayAudio_abschluss] = useSound(abschluss);
    const [PlayAudio_stimSound] = useSound(stimSound);
    const [PlayAudio_targetSound] = useSound(targetSound);

        
    {/*Variablen AUDIO*/ }

   
    useEffect(() =>{
        if(connect == "offline")
        {
            setApi("http://192.168.12.100:5000/");
        }  
        if(connect == "online")
        {
            var newApi = "https://www.aideserver.de:443/";
            setApi("https://www.aideserver.de:443/");
            checkNext("https://www.aideserver.de:443/");
        } 
          
    }, [])

    
   

    const TestAudio = () =>{
        PlayAudio_aud_Tom();
    }

    const launchDiagnosis = () =>{
        setViewAbschlussSeite("AbschlussSeite gone");
        setEndPanelText("Ergebnisse werden gespeichert!");
    }

    const checkNext = (a) =>{
        if(DemoParams == "true")
        {
            setDemoModus(true);
            setTestbogen("AiDe-L-M1");
            launchDiagnosis();
            setEnd_Panel('End_Panel_gone');
            setDemoPanel('DemoPanel');
        }
        else
        {

        if(schulerCode != null)
        {
        
        
        Axios.post(a+'checkNEXT',{schulerCode: schulerCode}).then((BogenResponse) => {
           
            setTestbogen(BogenResponse.data.bogen);
            setTransferTicket(BogenResponse.data.transferticket)
            var meta = parseInt(BogenResponse.data.meta);
            if(meta == 1)
            {
                setMetaDa(true);
            }

            setTimeout(() => {
                if(BogenResponse.data.bogen) 
                {
                    launchDiagnosis();
                }
    
                else
                {
                    checkNext("https://www.aideserver.de:443/")   
                }
            
            }, 2000)
        })

    }}
    }

    useEffect(()=>{
        if(testbogen == "AiDe-L-M1"){
            setItemAnzahlPhase1(20);
            setItemAnzahlPhase2(0);
            setItemAnzahlPhase3(0);
            setItemAnzahlPhase4(9);
            if(schulerKlasse > 2)
            {
                setItemAnzahlPhase4(18);
            }
        }

        if(testbogen == "AiDe-L-M2"){
            setItemAnzahlPhase1(20);
            setItemAnzahlPhase2(0);
            setItemAnzahlPhase3(0);
            setItemAnzahlPhase4(9);
            setReTest("true");
            if(schulerKlasse > 2)
            {
                setItemAnzahlPhase4(18);
            }
        }

        if(testbogen == "AiDe-L-G1"){
            setItemAnzahlPhase1(20);
            setItemAnzahlPhase2(0);
            setItemAnzahlPhase3(0);
            setItemAnzahlPhase4(0);
            setReTest("true");
        }

        if(testbogen == "AiDe-L-S1"){
            setItemAnzahlPhase1(20);
            setItemAnzahlPhase2(0);
            setItemAnzahlPhase3(0);
            setItemAnzahlPhase4(0);
            setReTest("true");
        }

        if(testbogen == "AiDe-L-W1"){
            setItemAnzahlPhase1(20);
            setItemAnzahlPhase2(0);
            setItemAnzahlPhase3(0);
            setItemAnzahlPhase4(0);
            setReTest("true");
        }

    },[testbogen])

    useEffect(()=>{
        if(Theme == 'SecretDucks')
        {
            setLehrerCodeFeld('LehrerCodeFeldSD');
            setSchulerKlassenFeld('SchulerKlassenFeldSD');
            setTestbogenFeld('TestbogenFeldSD');
            setHandigkeitFeld('HandigkeitFeldSD');
            setRechtshanderButtonaktiv('RechtshanderButtonSD aktiv');
            setLinkshanderButtonaktiv('LinkshanderButtonSD inaktiv');
            setVerbleibendeTestungenFeld('VerbleibendeTestungenFeldSD');
            setVersionsFeld('VersionsFeldSD');
            setLoginButton('LoginButtonSD');
        }

        if(Theme == 'Default')
        {
            setLehrerCodeFeld('LehrerCodeFeldDefault');
            setSchulerKlassenFeld('SchulerKlassenFeldDefault');
            setTestbogenFeld('TestbogenFeldDefault');
            setHandigkeitFeld('HandigkeitFeldDefault');
            setRechtshanderButtonaktiv('RechtshanderButtonDefault aktiv');
            setLinkshanderButtonaktiv('LinkshanderButtonDefault inaktiv');
            setVerbleibendeTestungenFeld('VerbleibendeTestungenFeldDefault');
            setVersionsFeld('VersionsFeldDefault');
            setLoginButton('LoginButtonDefault');
        }
    },[Theme])

    {/*Variablen f�r Ablauf*/ }
    const sequenz_intervall = [18200, 29300, 29400,1000, 15600, 25200, 36500,38500,3200, 9300, 16100,18000,
        3600, 9100, 9200, 9200,2000, 6100, 10100, 10200,1000, 2300, 2500, 2500,1300, 1500, 1500,2300, 2500, 2500,
        11000,2000, 10000, 22000, 29200, 31000,3200, 7400, 12200, 2000, 5600, 5600,9500, 
        2000, 2000, 2000,2000,2000,2000,2000,2000,2000,2000,2000]

    const tutorial_Items = ["efte", "elte", "erfte","h\u00f6nt", "h\u00f6lt", "hond",
        "Der Fisch singt ein Lied unter der Dusche.", "V\u00f6gel legen Eier.", "Erdbeeren sind ganz blau.", "img001", "Maus", "Maul", "efte", "elte", "elle"]

    
    const SIL_Images = [["SIL_Img1_1a", "SIL_Img1_1b", "SIL_Img1_1c"],
                        ["SIL_Img1_2a", "SIL_Img1_2b", "SIL_Img1_2c"],
                        ["SIL_Img1_3a", "SIL_Img1_3b", "SIL_Img1_3c"],
                        ["SIL_Img1_4a", "SIL_Img1_4b", "SIL_Img1_4c"],
                        ["SIL_Img1_5a", "SIL_Img1_5b", "SIL_Img1_5c"],
                        ["SIL_Img1_6a", "SIL_Img1_6b", "SIL_Img1_6c"],
                        ["SIL_Img2_1a", "SIL_Img2_1b", "SIL_Img2_1c"],
                        ["SIL_Img2_2a", "SIL_Img2_2b", "SIL_Img2_2c"],
                        ["SIL_Img2_3a", "SIL_Img2_3b", "SIL_Img2_3c"],
                        ["SIL_Img2_4a", "SIL_Img2_4b", "SIL_Img2_4c"],
                        ["SIL_Img2_5a", "SIL_Img2_5b", "SIL_Img2_5c"],
                        ["SIL_Img2_6a", "SIL_Img2_6b", "SIL_Img2_6c"],
                        ["SIL_Img3_1a", "SIL_Img3_1b", "SIL_Img3_1c"],
                        ["SIL_Img3_2a", "SIL_Img3_2b", "SIL_Img3_2c"],
                        ["SIL_Img3_3a", "SIL_Img3_3b", "SIL_Img3_3c"],
                        ["SIL_Img3_4a", "SIL_Img3_4b", "SIL_Img3_4c"],
                        ["SIL_Img3_5a", "SIL_Img3_5b", "SIL_Img3_5c"],
                        ["SIL_Img3_6a", "SIL_Img3_6b", "SIL_Img3_6c"],
                        ["SIL_Img5_1a", "SIL_Img5_1b", "SIL_Img5_1c"],
                        ["SIL_Img5_2a", "SIL_Img5_2b", "SIL_Img5_2c"],
                        ["SIL_Img5_3a", "SIL_Img5_3b", "SIL_Img5_3c"],
                        ["SIL_Img5_4a", "SIL_Img5_4b", "SIL_Img5_4c"],
                        ["SIL_Img5_5a", "SIL_Img5_5b", "SIL_Img5_5c"],
                        ["SIL_Img5_6a", "SIL_Img5_6b", "SIL_Img5_6c"],
                        ["SIL_Img6_1a", "SIL_Img6_1b", "SIL_Img6_1c"],
                        ["SIL_Img6_2a", "SIL_Img6_2b", "SIL_Img6_2c"],
                        ["SIL_Img6_3a", "SIL_Img6_3b", "SIL_Img6_3c"],
                        ["SIL_Img6_4a", "SIL_Img6_4b", "SIL_Img6_4c"],
                        ["SIL_Img6_5a", "SIL_Img6_5b", "SIL_Img6_5c"],
                        ["SIL_Img6_6a", "SIL_Img6_6b", "SIL_Img6_6c"],
                        ["SIL_Img7_1a", "SIL_Img7_1b", "SIL_Img7_1c"],
                        ["SIL_Img7_2a", "SIL_Img7_2b", "SIL_Img7_2c"],
                        ["SIL_Img7_3a", "SIL_Img7_3b", "SIL_Img7_3c"],
                        ["SIL_Img7_4a", "SIL_Img7_4b", "SIL_Img7_4c"],
                        ["SIL_Img7_5a", "SIL_Img7_5b", "SIL_Img7_5c"],
                        ["SIL_Img7_6a", "SIL_Img7_6b", "SIL_Img7_6c"]
                        ]
    {/*Variablen f�r Ablauf*/ }

    const setRechtshander = () =>{setRechtsy(true);if(Theme=='SecretDucks'){setRechtshanderButtonaktiv('RechtshanderButtonSD aktiv');setLinkshanderButtonaktiv('LinkshanderButtonSD inaktiv');} if(Theme=='Default'){setRechtshanderButtonaktiv('RechtshanderButtonDefault aktiv');setLinkshanderButtonaktiv('LinkshanderButtonDefault inaktiv');}}
    const setLinkshander = () =>{setRechtsy(false);if(Theme=='SecretDucks'){setRechtshanderButtonaktiv('RechtshanderButtonSD inaktiv');setLinkshanderButtonaktiv('LinkshanderButtonSD aktiv');} if(Theme=='Default'){setRechtshanderButtonaktiv('RechtshanderButtonDefault inaktiv');setLinkshanderButtonaktiv('LinkshanderButtonDefault aktiv');}}

    const LogIn = () => {
        PlayAudio_buttonSound();
        window.scrollTo(0, 0);

        if(DemoModus == true)
        {
            //log("Demo Modus!");
            orderItems();

        }
        else
        {

        
        
        if(connect == "online")
        {            
            if(schulerCode && userCode !== "Keiner")
            {
                setFullScreen();
                setLoginButton('LoginButton gone');
                if(transferTicket == 0)
                {
                    var zufallsTicket = Math.floor(Math.random() * 1000000);
                    setTestTicket(zufallsTicket);
                }
                

                if(transferTicket == null)
                {
                    var zufallsTicket = Math.floor(Math.random() * 1000000);
                    setTestTicket(zufallsTicket);
                }
                
                if(transferTicket > 0)
                {
                    setTestTicket(transferTicket+"_f");
                }
                
                orderItems();
            }
            else{
                 
            }
         }
         
         if(connect == "offline")
         {
                setLoginButton('LoginButton gone');
                var zufallsTicket = Math.floor(Math.random() * 1000000);
                setTestTicket(zufallsTicket);
                orderItems();
         }
        }
    }  


    {/*Kernmethoden*/ }
    const starteTutorial_Phase1 = () =>{startSeiteAusblenden();diagnoseSeiteEinblenden();setInTutorium(true);
        if(reTest === "false"){p1_tut1();} 
        if(reTest === "true"){s_reTut1();}}
        
    
    const startePhase1 = () => {startSeiteAusblenden();setInDiagnose(true);setTicketMonitor("TicketMonitor gone");setAdminTool("AdminTool");
        setProgressBarVisible("ProgressbarWrapper");
        setPhase1(true);
        setPhase2(false);
        setPhase3(false);
        cycle_Phase1();}

    const startePhase2 = () => {startSeiteAusblenden();setInDiagnose(true);setTicketMonitor("TicketMonitor gone");setAdminTool("AdminTool");
        setPhase1(false);
        setPhase2(true);
        setPhase3(false);
        setProgress(0);
        setProgressBarVisible("ProgressbarWrapper");
        cycle_Phase2();}

        const startePhase3 = () => {startSeiteAusblenden();setInDiagnose(true);setTicketMonitor("TicketMonitor gone");setAdminTool("AdminTool");
        setPhase1(false);
        setPhase2(false);
        setPhase3(true);
        setProgress(0);
        setProgressBarVisible("ProgressbarWrapper");
        cycle_Phase3();}
    
    const cycle_Phase1 = () => {
        var currentCycle = cycleCounter1;
        verbergeTargetKlein();
        setCycleCounter2(currentCycle);
        setCycleCounter1(cycleCounter1 + 1);
        setForceSteps(forceSteps+1);
        TargetCycle1(items[currentCycle][2], items[currentCycle][0],items[currentCycle][1]);
    }

    const cycle_Phase2 = () =>{
        var currentCycle = cycleCounter1;
        verbergeTargetKlein();
        setCycleCounter2(currentCycle);
        setCycleCounter1(cycleCounter1 + 1);
        TargetCycle2(items[currentCycle][2], items[currentCycle][0]);
    }

    const cycle_Phase3 = () => {
        var currentCycle = cycleCounter1;
        verbergeTargetKlein();
        setCycleCounter2(currentCycle);
        setCycleCounter1(cycleCounter1 + 1);
        setForceSteps(forceSteps+1);
        TargetCycle3(items[currentCycle][2], items[currentCycle][0]);
    }
    
    const TargetCycle1 = (tar, id, typ) =>{buttonVerbergen();TargetVerbergen();itemTafelVerbergen();stimulusVerbergen();zeigeLupeGross();PlayAudio_targetSound();
        setFullScreen();
        setTutorText('24b');
        setInHilfePhase(true);
        setTimeout(() => { setTutorScreen('Talk'); }, 0)
        setTimeout(() => { setTutorText('0')}, 2500)
        setTimeout(() => { setTutorScreen('Neutral'); setTutorText('0')}, 4000)
        setTimeout(() => { setTutorScreen('Android'); setTutorText('0')}, 5000)
        setTimeout(() => { zeigeTargetGroß(tar, typ); setHilfeWort(tar); zeitpunktTargetDarbietung(tar, id); }, 3500);
        setTimeout(() => { weiterButtonAktivieren(); setButtonAktiv(true); }, 3800);
      }

    const TargetCycle2 = (tar, id) =>{
        setFullScreen();
        setPhase1(false);
        setPhase2(true);
        setInHilfePhase(false);
        buttonVerbergen();
        TargetVerbergen();
        itemTafelVerbergen();
        stimulusVerbergen();
        setHilfeWort(tar);
        setViewSpeaker('TargetSpeaker');
        setSpeakerWork('SpeakerIcon_silent');
        setTimeout(() => { setTutorScreen('Talk'); }, 0)
        setTimeout(() => { setTutorText('0')}, 2500)
        setTimeout(() => { setTutorScreen('Neutral'); setTutorText('0')}, 4000)
        setTimeout(() => { setTutorScreen('Android'); setTutorText('0')}, 5000)
        setTimeout(() => { setSpeakerWork('SpeakerIcon_working'); playAudioTarget(tar); zeitpunktTargetDarbietung(tar, id);  }, 2500);
        setTimeout(() => { setSpeakerWork('SpeakerIcon_silent'); weiterButtonAktivieren(); setButtonAktiv(true); setInHilfePhase(true);}, 3500);
    }

    const TargetCycle3 = (tar, id) =>{
        setFullScreen();
        buttonVerbergen();
        TargetVerbergen();
        itemTafelVerbergen();
        stimulusVerbergen();
        zeigeLupeGross();
        PlayAudio_targetSound();
        setTutorText('24b');
        setInHilfePhase(true);
        setTimeout(() => { setTutorScreen('Talk'); }, 0)
        setTimeout(() => { setTutorText('0')}, 2500)
        setTimeout(() => { setTutorScreen('Neutral'); setTutorText('0')}, 4000)
        setTimeout(() => { setTutorScreen('Android'); setTutorText('0')}, 5000)
        setTimeout(() => { zeigeTargetGroß(tar, "3"); setHilfeWort(tar); zeitpunktTargetDarbietung(tar, id); }, 3500);
        setTimeout(() => { weiterButtonAktivieren(); setButtonAktiv(true); }, 3800);
      }

    const playAudioTarget = (tar) =>{
        
    }
        

    const zeitpunktTargetDarbietung = (tar, id) => {var targetDarbietungszeitpunkt = Date.now();
        setTargetOnsetTime(targetDarbietungszeitpunkt);
        var gewahltesItem = id;var gewahlterTarget = tar;targetUsed.push(gewahlterTarget);itemsUsed.push(gewahltesItem);}
    
    const zeitpunktTargetBearbeitet = () => {var targetBearbeitetZeitpunkt = Date.now();var targetZeitDifferenz = targetBearbeitetZeitpunkt - targetOnsetTime;targetDelta.push(targetZeitDifferenz);}

    const stimulusCycle = () => {var currentStimulus = stimulusCounter1;setStimulusCounter2(currentStimulus);setStimulusCounter1(stimulusCounter1 + 1);
        setInHilfePhase(false);
        TargetVerbergen();zeigeTargetKlein();itemTafelEinblenden();buttonDeaktivieren();stimulusEinblenden("...", "1");PlayAudio_timeSound();
        setTimeout(() => { stimulusEinblenden("..", "1"); PlayAudio_timeSound();buttonDeaktivieren() }, 1000)
        setTimeout(() => { stimulusEinblenden(".", "1"); PlayAudio_timeSound(); }, 2000)
        setTimeout(() => {stimulusEinblenden(items[cycleCounter2][3 + currentStimulus], items[cycleCounter2][1]);zeitpunktStimulusDarbietung();
            if (rechtsy === true) {buttonAktivierenRechtsy();}else {buttonAktivierenLinksy();}
            setButtonAktiv(true);PlayAudio_stimSound();}, 3000)}
    
    const stimulusCycle2 = () => {var currentStimulus = stimulusCounter1;setStimulusCounter2(currentStimulus);setStimulusCounter1(stimulusCounter1 + 1);
                setInHilfePhase(false);
                TargetVerbergen();
                setSpeakerWork('SpeakerIcon_silent_right');
                itemTafelEinblenden();
                buttonDeaktivieren();
                stimulusEinblenden("...", "1");
                setTimeout(() => { stimulusEinblenden("..", "1");buttonDeaktivieren(); setSpeakerWork('SpeakerIcon_silent_right'); }, 1000)
                setTimeout(() => { stimulusEinblenden(".", "1"); setSpeakerWork('SpeakerIcon_silent_right'); }, 2000)
                setTimeout(() => {stimulusEinblenden(items[cycleCounter2][3 + currentStimulus], items[cycleCounter2][1]);zeitpunktStimulusDarbietung();setInHilfePhase(true);
                    if (rechtsy === true) {buttonAktivierenRechtsy();}else {buttonAktivierenLinksy();}
                    setButtonAktiv(true);PlayAudio_stimSound(); setSpeakerWork('SpeakerIcon_silent_right');}, 3000)}
    
    const stimulusCycle3 = () => {var currentStimulus = stimulusCounter1;setStimulusCounter2(currentStimulus);setStimulusCounter1(stimulusCounter1 + 1);
        setInHilfePhase(false);
        TargetVerbergen();zeigeTargetKlein();itemTafelEinblenden();buttonDeaktivieren();stimulusEinblenden("...", "1");PlayAudio_timeSound();
        setTimeout(() => { stimulusEinblenden("..", "1"); PlayAudio_timeSound();buttonDeaktivieren() }, 1000)
        setTimeout(() => { stimulusEinblenden(".", "1"); PlayAudio_timeSound(); }, 2000)
        setTimeout(() => {stimulusEinblenden(items[cycleCounter2][3 + currentStimulus], items[cycleCounter2][1]);zeitpunktStimulusDarbietung();
            if (rechtsy === true) {buttonAktivierenRechtsy();}else {buttonAktivierenLinksy();}
            setButtonAktiv(true);PlayAudio_stimSound();}, 3000)}
    
    const stimulusCycle4 = () =>{
        var currentStimulus = SIL_Counter1;
        set_SIL_Counter2(currentStimulus);set_SIL_Counter1(SIL_Counter1 + 1)
        //console.log()
        if(parseInt(SIL_Item[currentStimulus][5])==8){setSIL_Textbereich_Textfeld('SIL_Textbereich_Textfeld'); console.log("ist 8");}
        if(parseInt(SIL_Item[currentStimulus][5])==9){setSIL_Textbereich_Textfeld('SIL_Textbereich_Textfeld_typ9'); console.log("ist 9");}
        buttonDeaktivieren();
        setInHilfePhase(false);
        setSIL_Textbereich('SIL_Textbereich');
        set_SIL_Target("...")
        setTimeout(() => { set_SIL_Target(".."); PlayAudio_timeSound();buttonDeaktivieren() }, 1000)
        setTimeout(() => { set_SIL_Target("."); PlayAudio_timeSound(); }, 2000)
        setTimeout(() => {set_SIL_Target(SIL_Item[currentStimulus][1]);zeitpunktSatzDarbierung();
            select_SIL_Images(SIL_Item[currentStimulus][0], SIL_Item[currentStimulus][2],SIL_Item[currentStimulus][3],SIL_Item[currentStimulus][4],)
            setButtonAktiv(true);PlayAudio_stimSound();}, 3000)
    }

    const select_SIL_Images = (itemId, pic1, pic2, pic3) =>{

            var row = 0;
            
            if (itemId == 10801){row = 0;}
            if (itemId == 10802){row = 1;}
            if (itemId == 10803){row = 2;}
            if (itemId == 10804){row = 3;}
            if (itemId == 10805){row = 4;}
            if (itemId == 10806){row = 5;}
            if (itemId == 10901){row = 6;}
            if (itemId == 10902){row = 7;}
            if (itemId == 10903){row = 8;}
            if (itemId == 10904){row = 9;}
            if (itemId == 10905){row = 10;}
            if (itemId == 10906){row = 11;}
            if (itemId == 11001){row = 12;}
            if (itemId == 11002){row = 13;}
            if (itemId == 11003){row = 14;}
            if (itemId == 11004){row = 15;}
            if (itemId == 11005){row = 16;}
            if (itemId == 11006){row = 17;}
            if (itemId == 13001){row = 18;}
            if (itemId == 13002){row = 19;}
            if (itemId == 13003){row = 20;}
            if (itemId == 13004){row = 21;}
            if (itemId == 13005){row = 22;}
            if (itemId == 13006){row = 23;}
            if (itemId == 14001){row = 24;}
            if (itemId == 14002){row = 25;}
            if (itemId == 14003){row = 26;}
            if (itemId == 14004){row = 27;}
            if (itemId == 14005){row = 28;}
            if (itemId == 14006){row = 29;}
            if (itemId == 15001){row = 30;}
            if (itemId == 15002){row = 31;}
            if (itemId == 15003){row = 32;}
            if (itemId == 15004){row = 33;}
            if (itemId == 15005){row = 34;}
            if (itemId == 15006){row = 35;}


            setSIL1(SIL_Images[row][pic1]);
            setSIL2(SIL_Images[row][pic2]);
            setSIL3(SIL_Images[row][pic3]);
            setSIL_Bildbereich_BildFeld1('SIL_Bildbereich_BildFeld1');
            setSIL_Bildbereich_BildFeld2('SIL_Bildbereich_BildFeld2');
            setSIL_Bildbereich_BildFeld3('SIL_Bildbereich_BildFeld3');
    }

    const zeitpunktStimulusDarbietung = () => {var stimulusDarbietungszeitpunkt = Date.now();setStimulusOnsetTime(stimulusDarbietungszeitpunkt);}
    const zeitpunktSatzDarbierung = () => {var stimulusDarbietungszeitpunkt = Date.now();setSatzItemOnsetTime(stimulusDarbietungszeitpunkt);}

    const zeitPunktSatzBearbeitet = (id, text) =>{
        var satzBearbeitetZeitpunkt = Date.now();
        var SatzDelta = satzBearbeitetZeitpunkt - SatzItemOnsetTime;
        var gewahlterSatz = id;
        var gewahlterText = text;
        SIL_Delta.push(SatzDelta);
        SIL_used.push(gewahlterSatz);
        SIL_text_used.push(gewahlterText);
    }

    const zeitPunktStimulusBearbeitet = (welcher) => {
        var stimulusBearbeitetZeitpunkt = Date.now();
        var gewahlterStimulus = items[cycleCounter2][3 + stimulusCounter2];
        var stimulusDelta = stimulusBearbeitetZeitpunkt - stimulusOnsetTime;
        setInHilfePhase(false);

        if (welcher === 0) {s1Delta.push(stimulusDelta);s1Used.push(gewahlterStimulus);}
        if (welcher === 1) {s2Delta.push(stimulusDelta);s2Used.push(gewahlterStimulus);}
        if (welcher === 2) {s3Delta.push(stimulusDelta);s3Used.push(gewahlterStimulus);}
        if (welcher === 3) {s4Delta.push(stimulusDelta);s4Used.push(gewahlterStimulus);}}
    
    const antworte = (mit, welche) => {var antwort = mit;
        if (welche === 0) {s1Antwort.push(antwort);}
        if (welche === 1) {s2Antwort.push(antwort);}
        if (welche === 2) {s3Antwort.push(antwort);}
        if (welche === 3) {s4Antwort.push(antwort);}
        if (antwort=== true && items[cycleCounter2][3 + welche] === items[cycleCounter2][2])
        {
            doStepLightSide();
        }
        if (antwort=== false && items[cycleCounter2][3 + welche] != items[cycleCounter2][2])
        {
            doStepLightSide();
        }

        if (antwort=== false && items[cycleCounter2][3 + welche] === items[cycleCounter2][2])
        {
            doStepDarkSide();
        }
        if (antwort=== true && items[cycleCounter2][3 + welche] != items[cycleCounter2][2])
        {
            doStepDarkSide();
        }

    }
        
    const loben = () => {

        if(mood > 7)
        {
            var lobauswahl = Math.floor(Math.random() * 10);
            if (lobauswahl === 0){ if(Phase1){cycle_Phase1();} if(Phase2){cycle_Phase2()} if(Phase3){cycle_Phase3()}}
            if (lobauswahl === 1) {PlayAudio_l1();setTutorScreen('Loben');setTimeout(() => { set_o_oneshot(false); if(Phase1){cycle_Phase1();} if(Phase2){cycle_Phase2()} if(Phase3){cycle_Phase3()} }, 4000)}
            if (lobauswahl === 2) {PlayAudio_l2();setTutorScreen('Loben');setTimeout(() => { set_o_oneshot(false); if(Phase1){cycle_Phase1();} if(Phase2){cycle_Phase2()} if(Phase3){cycle_Phase3()}}, 4000)}
            if (lobauswahl === 3) {PlayAudio_l3();setTutorScreen('Talk');setTimeout(() => {  set_o_oneshot(false); if(Phase1){cycle_Phase1();} if(Phase2){cycle_Phase2()} if(Phase3){cycle_Phase3()}}, 5000)}
            if (lobauswahl === 4) {PlayAudio_l4();setTutorScreen('Loben');setTimeout(() => { set_o_oneshot(false); if(Phase1){cycle_Phase1();} if(Phase2){cycle_Phase2()} if(Phase3){cycle_Phase3()}}, 5000)}
            if (lobauswahl === 5) {PlayAudio_l5();setTutorScreen('Loben');setTimeout(() => { set_o_oneshot(false); if(Phase1){cycle_Phase1();} if(Phase2){cycle_Phase2()} if(Phase3){cycle_Phase3()}}, 4000)}
            if (lobauswahl === 6) {PlayAudio_l6();setTutorScreen('Love');setTimeout(() => { set_o_oneshot(false); if(Phase1){cycle_Phase1();} if(Phase2){cycle_Phase2()} if(Phase3){cycle_Phase3()}}, 4000)}
            if (lobauswahl === 7) {PlayAudio_l7();setTutorScreen('Love');setTimeout(() => { set_o_oneshot(false); if(Phase1){cycle_Phase1();} if(Phase2){cycle_Phase2()} if(Phase3){cycle_Phase3()}}, 4000)}
            if (lobauswahl === 8) {PlayAudio_l8();setTutorScreen('Love');setTimeout(() => { set_o_oneshot(false); if(Phase1){cycle_Phase1();} if(Phase2){cycle_Phase2()} if(Phase3){cycle_Phase3()}}, 4000)}
            if (lobauswahl === 9) {if(Phase1){cycle_Phase1();} if(Phase2){cycle_Phase2()} if(Phase3){cycle_Phase3()}}
            if (lobauswahl === 10) {if(Phase1){cycle_Phase1();} if(Phase2){cycle_Phase2()} if(Phase3){cycle_Phase3()}}
        }
        if(mood <= 7 && mood >2)
        {
            var lobauswahl = Math.floor(Math.random() * 2);
            if (lobauswahl === 0) {PlayAudio_l1m();setTutorScreen('Doubt');setTimeout(() => { set_o_oneshot(false); if(Phase1){cycle_Phase1();} if(Phase2){cycle_Phase2()} if(Phase3){cycle_Phase3()}}, 4000)}
            if (lobauswahl === 1) {PlayAudio_l1m();setTutorScreen('Doubt');setTimeout(() => { set_o_oneshot(false); if(Phase1){cycle_Phase1();} if(Phase2){cycle_Phase2()} if(Phase3){cycle_Phase3()}}, 4000)}
            if (lobauswahl === 2) {PlayAudio_l2m();setTutorScreen('Doubt');setTimeout(() => { set_o_oneshot(false); if(Phase1){cycle_Phase1();} if(Phase2){cycle_Phase2()} if(Phase3){cycle_Phase3()}}, 4000)}

        }

        if(mood <= 2)
        {
            var lobauswahl = Math.floor(Math.random() * 2);
            if (lobauswahl === 0) {PlayAudio_l1s();setTutorScreen('StopIt');setTimeout(() => {set_o_oneshot(false); if(Phase1){cycle_Phase1();} if(Phase2){cycle_Phase2()} if(Phase3){cycle_Phase3()}}, 11000)}
            if (lobauswahl === 1) {PlayAudio_l1s();setTutorScreen('StopIt');setTimeout(() => {set_o_oneshot(false); if(Phase1){cycle_Phase1();} if(Phase2){cycle_Phase2()} if(Phase3){cycle_Phase3()}}, 11000)}
            if (lobauswahl === 2) {PlayAudio_l2s();setTutorScreen('StopIt');setTimeout(() => {set_o_oneshot(false); if(Phase1){cycle_Phase1();} if(Phase2){cycle_Phase2()} if(Phase3){cycle_Phase3()}}, 11000)}

        }
       
    }

    const helfen = () =>{
        if(inHilfePhase==true && e_oneshot == false)
        {
            set_o_oneshot(true);
            let bei = hilfeWort;               
                    if(bei=="Entenerpel"){PlayAudio_E_Entenerpel();setTutorScreen('Talk');setTimeout(() => { setTutorScreen('Neutral') }, 10000)}
                    if(bei=="Feigenkerne"){PlayAudio_E_Feigenkerne();setTutorScreen('Talk');setTimeout(() => { setTutorScreen('Neutral') }, 10000)}
                    if(bei=="verbogen"){PlayAudio_E_verbogen();setTutorScreen('Talk');setTimeout(() => { setTutorScreen('Neutral') }, 10000)}
                    if(bei=="geliket"){PlayAudio_E_geliket();setTutorScreen('Talk');setTimeout(() => { setTutorScreen('Neutral') }, 10000)}
                    if(bei=="Pudelbeine"){PlayAudio_E_Pudelbeine();setTutorScreen('Talk');setTimeout(() => { setTutorScreen('Neutral') }, 10000)}
                    if(bei=="Notenhefte"){PlayAudio_E_Notenhefte();setTutorScreen('Talk');setTimeout(() => { setTutorScreen('Neutral') }, 10000)}
                    if(bei=="gelogen"){PlayAudio_E_gelogen();setTutorScreen('Talk');setTimeout(() => { setTutorScreen('Neutral') }, 10000)}
                    if(bei=="downloaden"){PlayAudio_E_downloaden();setTutorScreen('Talk');setTimeout(() => { setTutorScreen('Neutral') }, 10000)}
        }          
    }

    const audioHilfe = () =>{
        if(help_reload == true && inHilfePhase == true)
        {
            if(Speaker_work == "SpeakerIcon_silent_right") {setSpeakerWork('SpeakerIcon_working_right'); setTimeout(() => {setSpeakerWork('SpeakerIcon_silent_right');},1000)}
            if(Speaker_work == "SpeakerIcon_silent") {setSpeakerWork('SpeakerIcon_working'); setTimeout(() => {setSpeakerWork('SpeakerIcon_silent');},1000)}
            setHelp_reload(false);
            let bei = hilfeWort;
            playAudioTarget(bei);  
            setTimeout(() => {setHelp_reload(true)},2000)
        }
    }
    
    const DiagnoseBeenden = () => {
        if(DemoModus == true)
        {
            stimulusVerbergen();
            buttonVerbergen();
            PlayAudio_abschluss();
            setTutorText('38b')
            setTimeout(() => { setTutorScreen('Talk'); setTutorText('105')}, 10)
            setTimeout(() => { setTutorScreen('Loben'); setTutorText('105b') }, 3200)
            setTimeout(() => { setTutorScreen('Talk'); setTutorText('105c') }, 9000)
            setTimeout(() => { setTutorScreen('Neutral'); }, 10000);
            setTimeout(() => { setTutorScreen('Android'); setDiagnoseSeite("diagnoseseite gone"); setViewAbschlussSeite('AbschlussSeite')}, 10000)
        }

        if(DemoModus == false)
        {
                
        if(connect == "offline")
        {
            stimulusVerbergen();
            buttonVerbergen();
            PlayAudio_abschluss();
            setTutorText('38b')
            setTimeout(() => { setTutorScreen('Talk'); setTutorText('105')}, 10)
            setTimeout(() => { setTutorScreen('Loben'); setTutorText('105b') }, 3200)
            setTimeout(() => { setTutorScreen('Talk'); setTutorText('105c') }, 9000)
            setTimeout(() => { setTutorScreen('Neutral'); }, 10000)
            setTimeout(() => { setTutorScreen('Android'); setDiagnoseSeite("diagnoseseite gone"); setViewAbschlussSeite('AbschlussSeite'); confirm_Result();}, 10000)
            
        }
        if(connect == "online")
        {
            stimulusVerbergen();
            buttonVerbergen();
            PlayAudio_abschluss();
            setTicketMonitor("TicketMonitor");
            setTutorText('38b')
            setTimeout(() => { setTutorScreen('Talk'); setTutorText('105')}, 10)
            setTimeout(() => { setTutorScreen('Loben'); setTutorText('105b');  }, 3200)
            setTimeout(() => { setTutorScreen('Talk'); setTutorText('105c');DiagnoseAuswerten();}, 9000)
            setTimeout(() => { setTutorScreen('Neutral'); }, 10000)
            setTimeout(() => { setTutorScreen('Android'); setDiagnoseSeite("diagnoseseite gone"); setViewAbschlussSeite('AbschlussSeite'); confirm_Result();}, 11000)
        }

        }

    }

    const doStepLightSide = () =>{
        if(theDarkSide >0)
        {setTheDarkSide(theDarkSide-20);}
        if(theLightSide < 255){setTheLightSide(theLightSide+20);}
        if(mood < 10){setMood(mood+2);}
    }

    const doStepDarkSide = () =>{if(theDarkSide < 255){setTheDarkSide(theDarkSide+20);}
       if(theLightSide > 0){setTheLightSide(theLightSide-20);}
        if(mood>0){setMood(mood-1);}}
    
    {/*Kernmethoden*/ }

    {/*ButtonClicks*/ }
    const handleStart = () =>{
        if(connect == "offline")
        {
            setFullScreen();
            if(userCode != null)
            {
                Kindanlegen();
                setSzene('diagnoseszene');
                starteTutorial_Phase1();
                        
            }
            else{
                localLogin();
            }
        }
        if(connect == "online")
        {
            setFullScreen();
            setSzene('diagnoseszene');starteTutorial_Phase1();
        }
        
        
    } 

    const handleWeiterClick = () =>{if (buttonAktiv){
        if(inDiagnose){setButtonAktiv(false)};
            if (inTutorium) {if (tutCounter === 1) { p1_tut2(); }if (tutCounter === 6) { p1_tut6(); } if (tutCounter === 11) { s_tut12(); } if (tutCounter === 80) {s_reTut2();} if(tutCounter === 21){phase2_tut2();} if(tutCounter === 31) {phase3_tut2()}}
            if (inDiagnose)
            {
                if(Phase1)
                {
                    weiterButtonDeaktivieren();buttonDeaktivieren();zeitpunktTargetBearbeitet();stimulusCycle(); 
                    if (rechtsy === true) {buttonAktivierenRechtsy();}else {buttonAktivierenLinksy();} 
                } 
                
                if(Phase2)
                {
                    weiterButtonDeaktivieren();buttonDeaktivieren();zeitpunktTargetBearbeitet();stimulusCycle2(); 
                    if (rechtsy === true) {buttonAktivierenRechtsy();}else {buttonAktivierenLinksy();} 
                }
                
                if(Phase3)
                {
                    weiterButtonDeaktivieren();buttonDeaktivieren();zeitpunktTargetBearbeitet();stimulusCycle3(); 
                    if (rechtsy === true) {buttonAktivierenRechtsy();}else {buttonAktivierenLinksy();} 
                } 
            }
            buttonDeaktivieren();}}

    
    const handleRechtsClick = () =>{if (buttonAktiv) {if(inDiagnose){setButtonAktiv(false)}; if (rechtsy) {handleJa();}if (rechtsy === false) {handleNein();}}}
    const handleLinksClick = () =>{if (buttonAktiv) {if(inDiagnose){setButtonAktiv(false)};if (rechtsy) {handleNein();}if (rechtsy === false) {handleJa();}}}

    const handleJa = () =>
    {
        if (inTutorium) {if (tutCounter === 2) { p1_tut3();}if (tutCounter === 7) { p1_tut7();}if (tutCounter === 9) { p1_tut9(); } if (tutCounter === 21) { p1_tut9();}if (tutCounter === 22) { phase2_tut3(); } if (tutCounter === 12) { s_tut13();} if (tutCounter === 81) { s_reTut3();} if(tutCounter===24) {phase2_tut_fertig()} if(tutCounter===32){phase3_tut3()} if(tutCounter===34){p1_tut_phase1_finish();}}
        
        if (inDiagnose){PlayAudio_buttonSound();setButtonAktiv(false);zeitPunktStimulusBearbeitet(stimulusCounter2);antworte(true, stimulusCounter2);buttonDeaktivieren();
            if(Phase1)
            {
                if (cycleCounter2 < itemAnzahlPhase1-1) {
                    if (stimulusCounter2 < 3){stimulusCycle();}else{setStimulusCounter1(0);loben();}}
                if (cycleCounter2 === itemAnzahlPhase1-1) {
                    if (stimulusCounter2 < 3) {stimulusCycle();} 
                    if (stimulusCounter2 === 3) {setStimulusCounter1(0);phase2_tut1();setPhase1(false);}
                    if (stimulusCounter2 > 3) {setStimulusCounter1(0);phase4_tut1();setPhase1(false);}}
                if (cycleCounter2 > itemAnzahlPhase1-1) {setStimulusCounter1(0);phase4_tut1();setPhase1(false);}
                var newProgress = progress+(100/((itemGruppe0.length+itemGruppe1.length)*4));
                setProgress(newProgress);
            }
            }
           
    }

    const handleNein = () =>
    {
        if (inTutorium) {if (tutCounter === 3) { p1_tut4();}if (tutCounter === 8) { p1_tut8();}if (tutCounter === 5) { p1_tut5(); }if (tutCounter === 11){ p1_tut_phase1_finish();} if (tutCounter === 13){ p1_tut_phase1_finish();} if (tutCounter === 82){ p1_tut_phase1_finish();} if(tutCounter === 23){phase2_tut4();} if(tutCounter===33){p1_tut_phase1_finish();}}

        if (inDiagnose) {PlayAudio_buttonSound();setButtonAktiv(false);zeitPunktStimulusBearbeitet(stimulusCounter2);antworte(false, stimulusCounter2);buttonDeaktivieren();
            if(Phase1)
            {
                if (cycleCounter2 < itemAnzahlPhase1-1) {
                    if (stimulusCounter2 < 3) {stimulusCycle();} 
                    else {setStimulusCounter1(0);loben();}}
            if (cycleCounter2 === itemAnzahlPhase1-1) {
                if (stimulusCounter2 < 3) {stimulusCycle();}
                if (stimulusCounter2 === 3) {console.log("Logging: Gehe in Phase 2"); setStimulusCounter1(0);phase4_tut1();setPhase1(false);} 
                if (stimulusCounter2 > 3) {setStimulusCounter1(0);phase2_tut1();setPhase1(false); setPhase2(true)}}
            if (cycleCounter2 > itemAnzahlPhase1-1) {console.log("Logging: Gehe in Phase 2"); setStimulusCounter1(0);phase4_tut1();setPhase1(false);}
            var newProgress = progress+(100/((itemGruppe0.length+itemGruppe1.length)*4));
            setProgress(newProgress);
            }
            } 
        }

    const handleBild1Click = () =>{
        if(inTutorium == true){}
        if(inTutorium == false)
        {
            zeitPunktSatzBearbeitet(SIL_Item[SIL_Counter2][0], SIL_Item[SIL_Counter2][1]);
            setSIL_Bildbereich_BildFeld1('SIL_Bildbereich_BildFeld1_gone');
            setSIL_Bildbereich_BildFeld2('SIL_Bildbereich_BildFeld2_gone');
            setSIL_Bildbereich_BildFeld3('SIL_Bildbereich_BildFeld3_gone');
            if(SIL_Item[SIL_Counter2][2] == 0)
            {
                console.log("Richtig!");
                SIL_korrekt.push(1);
            }

            if(SIL_Item[SIL_Counter2][2] != 0)
            {
                console.log("Falsch!");
                SIL_korrekt.push(0);
            }

            if(SIL_Counter2 < itemAnzahlPhase4-1)
            {
                stimulusCycle4();
            }

            if(SIL_Counter2 == itemAnzahlPhase4-1)
            {
                meta_phase1();
            }
            var newProgress = progress+25;
            setProgress(newProgress);
            
        }

    }

    const handleBild2Click = () =>{
        if(inTutorium == true && buttonAktiv == true)
        {
            if(tutCounter == 41){phase4_tut2();}
        }
        if(inTutorium == false){
            zeitPunktSatzBearbeitet(SIL_Item[SIL_Counter2][0], SIL_Item[SIL_Counter2][1]);
            setSIL_Bildbereich_BildFeld1('SIL_Bildbereich_BildFeld1_gone');
            setSIL_Bildbereich_BildFeld2('SIL_Bildbereich_BildFeld2_gone');
            setSIL_Bildbereich_BildFeld3('SIL_Bildbereich_BildFeld3_gone');

            if(SIL_Item[SIL_Counter2][3] == 0)
            {
                console.log("Richtig!");
                SIL_korrekt.push(1);
            }

            if(SIL_Item[SIL_Counter2][3] != 0)
            {
                console.log("Falsch!");
                SIL_korrekt.push(0);

            }

            if(SIL_Counter2 < itemAnzahlPhase4-1)
            {
                stimulusCycle4();
            }

            if(SIL_Counter2 == itemAnzahlPhase4-1)
            {
                meta_phase1();
            }
        }
    }

    const handleBild3Click = () =>{
        if(inTutorium == true){}
        if(inTutorium == false){
            zeitPunktSatzBearbeitet(SIL_Item[SIL_Counter2][0], SIL_Item[SIL_Counter2][1]);
            setSIL_Bildbereich_BildFeld1('SIL_Bildbereich_BildFeld1_gone');
            setSIL_Bildbereich_BildFeld2('SIL_Bildbereich_BildFeld2_gone');
            setSIL_Bildbereich_BildFeld3('SIL_Bildbereich_BildFeld3_gone');
            if(SIL_Item[SIL_Counter2][4] == 0)
            {
                console.log("Richtig!");
                SIL_korrekt.push(1);
            }

            if(SIL_Item[SIL_Counter2][4] != 0)
            {
                console.log("Falsch!");
                SIL_korrekt.push(0);
            }

            if(SIL_Counter2 < itemAnzahlPhase4-1)
            {
                stimulusCycle4();
            }

            if(SIL_Counter2 == itemAnzahlPhase4-1)
            {
                meta_phase1();
            }
        }

    }

    {/*ButtonClicks*/ }

    {/*Steuerung der Componenten*/ }
    const startSeiteAusblenden = () => {setDatenSeite('datenseite gone');}
    const diagnoseSeiteEinblenden = () =>{setDiagnoseSeite('diagnoseseite');}
    const zeigeTargetGroß = (t, ty) => { setTargetVisible('TargetWrapperGroß'); setTargetText(t); setTargetType(ty)}
    const weiterButtonAktivieren = () => {if(Theme=="SecretDucks"){setWeiterButton('WeiterButtonSD');}if(Theme=="Default"){setWeiterButton('WeiterButtonDefault');}}
    const weiterButtonDeaktivieren = () => {setWeiterButton('WeiterButton gone');}
    const TargetVerbergen = () => {setTargetVisible('TargetWrapperGroß gone');}
    const zeigeLupeGross = () => {}
    const zeigeTargetKlein = (t3) => {setTargetKleinVisible('TargetKleinWrapper')}
    const verbergeTargetKlein = () => {setTargetKleinVisible('TargetKleinWrapper gone')}
    const itemTafelEinblenden = () => {setStimulusWrapper('StimulusWrapper');}
    const itemTafelVerbergen = () => {setStimulusWrapper('StimulusWrapper gone');}
    const stimulusEinblenden = (t2, ty) => {setStimulusText(t2); setStimulusType(ty);}
    const jaButtonEinblendenRechtsy = () => {setButtonRechts('ButtonRechts'); setButtonRechtsType('Same');}
    const neinButtonEinblendenRechtsy = () => {setButtonLinks('ButtonLinks'); setButtonLinksType('Different');}
    const jaButtonEinblendenLinksy = () => {setButtonLinks('ButtonLinks'); setButtonLinksType('Same');}
    const neinButtonEinblendenLinksy = () => {setButtonRechts('ButtonRechts'); setButtonRechtsType('Different');}
    const buttonDeaktivieren = () => {setButtonRechtsType('Inaktiv');setButtonLinksType('Inaktiv');}
    const stimulusVerbergen = () => {setStimulusText('')}
    const buttonVerbergen = () => {setButtonRechts('ButtonRechts gone'); setButtonLinks('ButtonLinks gone');}
    const buttonAktivierenRechtsy = () => {setButtonRechts('ButtonRechts'); setButtonRechtsType('Same');setButtonLinks('ButtonLinks'); setButtonLinksType('Different');}
    const buttonAktivierenLinksy = () => {setButtonLinks('ButtonLinks'); setButtonLinksType('Same');setButtonRechts('ButtonRechts'); setButtonRechtsType('Different');}
    {/*Steuerung der Componenten*/ }


    {/*Skripting*/ }
    

    const p1_tut1 = () => {setTutCounter(1);zeigeLupeGross();
            PlayAudio_mod1();
            setTimeout(() => { setTutorScreen('Talk') }, 500)
            setTimeout(() => { setTutorText('1') }, 600)
            setTimeout(() => { setTutorScreen('Think');setTutorText('2') }, 11900)
            setTimeout(() => { setTutorScreen('Talk');}, 15500)
            setTimeout(() => { setTutorText('3') }, 16000)
            setTimeout(() => { setTutorScreen('Point') }, 19000)
            setTimeout(() => { setTutorText('4') }, 19500)
            setTimeout(() => { setTutorScreen('Talk') }, 21000)
            setTimeout(() => { setTutorText('5') }, 25000)
            setTimeout(() => { setTutorScreen('Point') }, 29000)
            setTimeout(() => { zeigeTargetGroß(tutorial_Items[0], "1"); }, 19500)
            setTimeout(() => { weiterButtonAktivieren(); }, 30000)
            setTimeout(() => { setTutorScreen('Neutral'); setTutorText('0') }, 31000)
            setTimeout(() => { setButtonAktiv(true); }, 33000)
               
        
    }
       
    
    const p1_tut2 = () => {setTutCounter(2);TargetVerbergen(); weiterButtonDeaktivieren();setButtonAktiv(false);zeigeTargetKlein(tutorial_Items[0],"1");;setTutorText('5b'); 
        PlayAudio_mod2();
        setTimeout(() => { setTutorScreen('Talk'); setTutorText('6') }, 100)
        setTimeout(() => { setTutorScreen('Point') }, 8000)
        setTimeout(() => { itemTafelEinblenden();stimulusEinblenden(tutorial_Items[0], "1"); }, 8500)
        setTimeout(() => { setTutorScreen('Talk') }, 9000)
        setTimeout(() => { setTutorText('7') }, 9500)
        setTimeout(() => { setTutorText('8') }, 21000)
        setTimeout(() => { setTutorScreen('Point') }, 18500)
        setTimeout(() => { setTutorScreen('Talk') }, 20500)
        setTimeout(() => { setTutorScreen('Point') }, 29000)
        setTimeout(() => { setTutorScreen('Talk') }, 30500)
        setTimeout(() => { setTutorScreen('Neutral'); setTutorText('0') }, 31000)
        setTimeout(() => { if (rechtsy === true) {jaButtonEinblendenRechtsy();}else {jaButtonEinblendenLinksy();}}, 21000)
        setTimeout(() => { if (rechtsy === true) {neinButtonEinblendenRechtsy();}else {neinButtonEinblendenLinksy();}}, 30000)
        setTimeout(() => { setButtonAktiv(true); }, 33000)}
    
    const p1_tut3 = () => {setTutCounter(3);setButtonAktiv(false);buttonDeaktivieren();stimulusVerbergen();PlayAudio_mod3();setTutorText('8b');
        setTimeout(() => { setTutorScreen('Talk'); setTutorText('9') }, 100)
        setTimeout(() => { setTutorScreen('Point') }, 2500)
        setTimeout(() => { stimulusEinblenden(tutorial_Items[1], "1"); }, 3200)
        setTimeout(() => { setTutorScreen('Talk'); setTutorText('10') }, 4000)
        setTimeout(() => { if (rechtsy === true) {jaButtonEinblendenRechtsy();}else {jaButtonEinblendenLinksy();}}, 13800)
        setTimeout(() => {if (rechtsy === true) {neinButtonEinblendenRechtsy();}else {neinButtonEinblendenLinksy();}}, 13800)
        setTimeout(() => { setTutorScreen('Neutral'); setTutorText('0') }, 13000)
        setTimeout(() => { setButtonAktiv(true); }, 14000)} 
    
    const p1_tut4 = () => {setTutCounter(5);setButtonAktiv(false);buttonDeaktivieren();stimulusVerbergen();PlayAudio_mod4();setTutorText('10b');
        setTimeout(() => { setTutorScreen('Talk'); setTutorText('11') }, 100)
        setTimeout(() => { setTutorScreen('Point') }, 2500)
        setTimeout(() => { setTutorScreen('Talk') }, 4000)
        setTimeout(() => { stimulusEinblenden(tutorial_Items[2], "1"); }, 3500)
        setTimeout(() => {if (rechtsy === true) {jaButtonEinblendenRechtsy();}else {jaButtonEinblendenLinksy();}}, 8500)
        setTimeout(() => {if (rechtsy === true) {neinButtonEinblendenRechtsy();}else {neinButtonEinblendenLinksy();}}, 8500)
        setTimeout(() => { setTutorScreen('Neutral'); setTutorText('0') }, 9000)
        setTimeout(() => { setButtonAktiv(true); }, 8500)}
    
    const p1_tut5 = () => {setTutCounter(6);setButtonAktiv(false);stimulusVerbergen();itemTafelVerbergen();TargetVerbergen();buttonVerbergen();PlayAudio_mod6();verbergeTargetKlein();
        setTutorText('11b');
        setTimeout(() => { setTutorScreen('Think'); setTutorText('12') }, 100)
        setTimeout(() => { setTutorScreen('Talk'); setTutorText('13')}, 3000)
        setTimeout(() => { setTutorScreen('Oh') }, 7000)
        setTimeout(() => { setTutorScreen('Talk') }, 8500)
        setTimeout(() => { setTutorScreen('Point') }, 9000)
        setTimeout(() => { setTutorScreen('Talk') }, 10500)
        setTimeout(() => { zeigeTargetGroß(tutorial_Items[3], "1"); }, 10000)
        setTimeout(() => { setTutorText('14')}, 9000)
        setTimeout(() => { weiterButtonAktivieren(); }, 14500)
        setTimeout(() => { setTutorScreen('Neutral'); setTutorText('0') }, 15000)
        setTimeout(() => { setButtonAktiv(true); }, 15000)}  
    
    const p1_tut6 = () => {setTutCounter(7);weiterButtonDeaktivieren();setButtonAktiv(false);zeigeTargetKlein();TargetVerbergen();buttonDeaktivieren();PlayAudio_mod7();setTutorText('14b');
        setTimeout(() => { setTutorScreen('Talk'); setTutorText('15')}, 0)
        setTimeout(() => { setTutorScreen('Point') }, 2000)
        setTimeout(() => { setTutorScreen('Talk') }, 3500)
        setTimeout(() => { itemTafelEinblenden(); }, 3000)
        setTimeout(() => { stimulusEinblenden(tutorial_Items[3], "1"); }, 3500)
        setTimeout(() => {if (rechtsy === true) {buttonAktivierenRechtsy();}else {buttonAktivierenLinksy();}}, 9000)
        setTimeout(() => { setTutorScreen('Neutral'); setTutorText('0') }, 9200)
        setTimeout(() => { setButtonAktiv(true); }, 9500)}
    
    const p1_tut7 = () => {setTutCounter(8);setButtonAktiv(false);buttonDeaktivieren();stimulusVerbergen();PlayAudio_mod8(); setTutorText('15b');
        setTimeout(() => { setTutorScreen('Talk'); setTutorText('16')}, 10)
        setTimeout(() => { setTutorScreen('Point') }, 1000)
        setTimeout(() => { setTutorScreen('Talk') }, 2000)
        setTimeout(() => { stimulusEinblenden(tutorial_Items[4], "1"); }, 1500)
        setTimeout(() => {if (rechtsy === true) {buttonAktivierenRechtsy();}else {buttonAktivierenLinksy();}}, 5500)
        setTimeout(() => { setTutorScreen('Neutral'); setTutorText('0') }, 6000)
        setTimeout(() => { setButtonAktiv(true); }, 6500)}
    
    const p1_tut8 = () => {setTutCounter(9);setButtonAktiv(false);buttonDeaktivieren();stimulusVerbergen();PlayAudio_mod9();setTutorText('16b');
        setTimeout(() => { setTutorScreen('Talk'); setTutorText('17')}, 10)
        setTimeout(() => { stimulusEinblenden(tutorial_Items[3],"1"); }, 2000)
        setTimeout(() => {if (rechtsy === true) {buttonAktivierenRechtsy();}else {buttonAktivierenLinksy();}}, 2800)
        setTimeout(() => { setTutorScreen('Neutral'); setTutorText('0') }, 4000)
        setTimeout(() => { setButtonAktiv(true); }, 4500)}

    const p1_tut9 = () => {
        verbergeTargetKlein();
        PlayAudio_p3_tut1();
        startSeiteAusblenden();
        setInTutorium(true);
        setInDiagnose(false);
        setButtonAktiv(false);
        stimulusVerbergen();
        itemTafelVerbergen();
        TargetVerbergen();
        buttonVerbergen();
        setTutCounter(31);
        setTutorScreen('Talk');
        setTutorText('30');
        setTimeout(()=>{setTutorScreen('Talk');},2500);
        setTimeout(()=>{setTutorText('30b')},3000);
        setTimeout(()=>{setTutorScreen('Neutral');},7500);
        setTimeout(()=>{setTutorScreen('Talk');},8200);
        setTimeout(()=>{setTutorText('30c')},9000);
        setTimeout(()=>{setTutorScreen('Point');},9500);
        setTimeout(() => { zeigeTargetGroß(tutorial_Items[9], "3"); }, 11000);
        setTimeout(()=>{setTutorScreen('Talk');},12000);
        setTimeout(()=>{setTutorText('30d')},13000);
        setTimeout(() => { weiterButtonAktivieren(); }, 18000)
        setTimeout(() => { setButtonAktiv(true); setInHilfePhase(true);}, 20000)
        setTimeout(() => { setTutorScreen('Neutral'); setTutorText('0');}, 20000);
    }


    const p1_tut_phase1_finish = () => {setTutCounter(10);PlayAudio_mod13();stimulusVerbergen();itemTafelVerbergen();verbergeTargetKlein();buttonVerbergen();TargetVerbergen();setInTutorium(false);
        setTimeout(() => { setTutorScreen('Loben'); setTutorText('23')}, 10)
        setTimeout(() => { setTutorScreen('Talk'); }, 2500)
        setTimeout(() => { setTutorScreen('Loben'); setTutorText('24')}, 11000)
        setTimeout(() => { setTutorScreen('Neutral'); setTutorText('0') }, 13000)
        setTimeout(() => { startePhase1(); }, 13000)}   
    
    
    const phase2_tut1 = () => {
        if(itemAnzahlPhase2 > 0)
        {
        setProgressBarVisible("ProgressbarWrapper gone");
        setSpeakerWork('SpeakerIcon_silent');
        verbergeTargetKlein();
        PlayAudio_p2_tut1();
        startSeiteAusblenden();
        setInTutorium(true);
        setInDiagnose(false);
        setButtonAktiv(false);
        stimulusVerbergen();
        itemTafelVerbergen();
        TargetVerbergen();
        buttonVerbergen();
        setTutCounter(21);
        setTutorScreen('Talk');
        setTutorText('25');
        setHilfeWort("dote");
        setTargetType("1");
        setTimeout(()=>{setTutorScreen('Loben');},1000)
        setTimeout(()=>{setTutorScreen('Talk');},3000)
        setTimeout(()=>{setTutorText('25b');},6000)
        setTimeout(()=>{setTutorText('25c');},12000)
        setTimeout(()=>{setViewSpeaker('TargetSpeaker')},10000)
        setTimeout(()=>{setSpeakerWork('SpeakerIcon_working');},14000)
        setTimeout(()=>{setSpeakerWork('SpeakerIcon_silent');},15000)
        setTimeout(() => { setTutorScreen('Point');}, 20500)
        setTimeout(()=>{setTutorText('25d');},16500)
        setTimeout(() => { weiterButtonAktivieren(); }, 21000)
        setTimeout(() => { setTutorScreen('Talk');}, 22000)
        setTimeout(()=>{setTutorText('25e');},22000)
        setTimeout(()=>{setSpeakerWork('SpeakerIcon_working');},28000)
        setTimeout(()=>{setSpeakerWork('SpeakerIcon_silent');},30000)
        setTimeout(()=>{setTutorText('25f');},31000)
        setTimeout(()=>{setTutorScreen('Neutral');},31000)
        setTimeout(() => { setButtonAktiv(true); setInHilfePhase(true);}, 31000)
        setTimeout(()=>{setTutorText('25g');},31500)
        }
        else
        phase3_tut1();
    }

    const phase2_tut2 = () =>{
        setInHilfePhase(false);
        weiterButtonDeaktivieren();
        setButtonAktiv(false);
        setTutCounter(22);
        setSpeakerWork('SpeakerIcon_silent_right');
        PlayAudio_p2_tut2();
        setHilfeWort("dote");
        setTimeout(()=>{setTutorScreen('Talk');},1000)
        setTimeout(()=>{setTutorText('26');},500)
        setTimeout(()=>{setTutorText('26b');},6000)
        setTimeout(() => { setTutorScreen('Point') }, 6500);
        setTimeout(() => { itemTafelEinblenden();stimulusEinblenden("dote", "1");  setSpeakerWork('SpeakerIcon_silent_right'); }, 6800);
        setTimeout(() => { setTutorScreen('Talk') }, 7500);
        setTimeout(()=>{setTutorText('26c');},9000)
        setTimeout(()=>{setTutorText('26d');},16000)
        setTimeout(() => { setTutorScreen('Point') }, 14000);
        setTimeout(() => { if (rechtsy === true) {jaButtonEinblendenRechtsy();}else {jaButtonEinblendenLinksy();}}, 14500)
        setTimeout(() => { setTutorScreen('Talk') }, 15500);
        setTimeout(() => { setTutorScreen('Point') }, 20500);
        setTimeout(() => { if (rechtsy === true) {neinButtonEinblendenRechtsy();}else {neinButtonEinblendenLinksy();}}, 21000)
        setTimeout(()=>{setTutorScreen('Neutral');setInHilfePhase(true);},23000)
        setTimeout(()=>{setTutorText('26e');},22000)
        setTimeout(() => { setButtonAktiv(true); }, 23000)
    }

    const phase2_tut3 = () =>{
        PlayAudio_p2_tut3();
        setSpeakerWork('SpeakerIcon_silent_right');
        setInHilfePhase(false);
        setTutCounter(23);
        setButtonAktiv(false);
        buttonDeaktivieren();
        stimulusVerbergen();
        setTutorText('27');
        setTimeout(()=>{setTutorScreen('Talk');},1000);
        setTimeout(() => { setTutorScreen('Point') }, 3000);
        setTimeout(() => { itemTafelEinblenden();stimulusEinblenden("dobe", "1"); }, 3500);
        setTimeout(() => { setTutorScreen('Talk') }, 4000);
        setTimeout(() => {if (rechtsy === true) {buttonAktivierenRechtsy();}else {buttonAktivierenLinksy();}}, 9500)
        setTimeout(() => { setTutorScreen('Neutral'); setTutorText('0');setInHilfePhase(true);}, 9500)
        setTimeout(() => { setButtonAktiv(true); }, 10000)

    }

    const phase2_tut4 = () =>{
        PlayAudio_p2_tut4();
        setSpeakerWork('SpeakerIcon_silent_right');
        setInHilfePhase(false);
        setTutCounter(24);
        setButtonAktiv(false);
        buttonDeaktivieren();
        stimulusVerbergen();
        setTutorText('28');
        setTimeout(()=>{setTutorScreen('Talk');},1000);
        setTimeout(()=>{setTutorScreen('Point');},2000);
        setTimeout(() => { itemTafelEinblenden();stimulusEinblenden("dote", "1"); }, 2200);
        setTimeout(()=>{setTutorScreen('Talk');},3500);
        setTimeout(() => {if (rechtsy === true) {buttonAktivierenRechtsy();}else {buttonAktivierenLinksy();}}, 5800)
        setTimeout(() => { setTutorScreen('Neutral'); setTutorText('0');setInHilfePhase(true); }, 6000)
        setTimeout(() => { setButtonAktiv(true); }, 6500)
    }

    const phase2_tut_fertig = () => {
        setTutCounter(25);
        setInTutorium(false);
        setInHilfePhase(false);
        PlayAudio_p2_tut5();
        stimulusVerbergen();
        itemTafelVerbergen();
        verbergeTargetKlein();
        buttonVerbergen();
        TargetVerbergen();
        setInTutorium(false);
        setTutorScreen('Talk');
        setTutorText('29');
        setTimeout(()=>{setTutorScreen('Loben');},1000);
        setTimeout(()=>{setTutorScreen('Talk');},2000);
        setTimeout(()=>{setTutorText('29b')},3500);
        setTimeout(()=>{setSpeakerWork('SpeakerIcon_working_right');},14200);
        setTimeout(()=>{setSpeakerWork('SpeakerIcon_silent_right');},15500);
        setTimeout(()=>{setTutorScreen('Talk');setTutorText('29c');},8000);
        setTimeout(()=>{setTutorScreen('Talk');setTutorText('29d');},20000);
        setTimeout(()=>{setTutorScreen('Talk');setTutorText('29e');},23000);
        setTimeout(() => { setTutorScreen('Neutral'); setTutorText('0');setSpeakerWork('SpeakerIcon_gone');  }, 24000);
        setTimeout(()=>{startePhase2()},25000);
    }

    const phase3_tut1 = () => {
        if(itemAnzahlPhase3>0)
        {
        setProgressBarVisible("ProgressbarWrapper gone");
        setViewSpeaker('TargetSpeaker_gone');
        setSpeakerWork('SpeakerIcon_gone');
        verbergeTargetKlein();
        PlayAudio_p3_tut1();
        startSeiteAusblenden();
        setInTutorium(true);
        setInDiagnose(false);
        setButtonAktiv(false);
        stimulusVerbergen();
        itemTafelVerbergen();
        TargetVerbergen();
        buttonVerbergen();
        setTutCounter(31);
        setTutorScreen('Loben');
        setTutorText('30');
        setTimeout(()=>{setTutorScreen('Talk');},2500);
        setTimeout(()=>{setTutorText('30b')},6500);
        setTimeout(()=>{setTutorText('30c')},12500);
        setTimeout(()=>{setTutorScreen('Point');},15000);
        setTimeout(() => { zeigeTargetGroß(tutorial_Items[9], "3"); }, 16000);
        setTimeout(()=>{setTutorScreen('Talk');},16500);
        setTimeout(()=>{setTutorText('30d')},17000);
        setTimeout(() => { weiterButtonAktivieren(); }, 23500)
        setTimeout(() => { setButtonAktiv(true); setInHilfePhase(true);}, 25000)
        setTimeout(() => { setTutorScreen('Neutral'); setTutorText('0');}, 25000);
        }
        else
        {
            phase4_tut1();
        }
    }

    const phase3_tut2 = () =>{
        setTutCounter(32);
        weiterButtonDeaktivieren();
        setTutorScreen('Talk');
        setButtonAktiv(false);
        zeigeTargetKlein();
        TargetVerbergen();
        buttonDeaktivieren();
        PlayAudio_p3_tut2();
        setTutorText('31');
        setTimeout(() => { setTutorText('31b')}, 5000);
        setTimeout(() => { itemTafelEinblenden(); }, 5500)
        setTimeout(() => { setTutorScreen('Point') }, 5000)
        setTimeout(() => { setTutorScreen('Talk') }, 7500)
        setTimeout(() => { stimulusEinblenden(tutorial_Items[10], "3"); }, 6000)
        setTimeout(() => { if (rechtsy === true) {jaButtonEinblendenRechtsy();}else {jaButtonEinblendenLinksy();}}, 11700)
        setTimeout(() => { if (rechtsy === true) {neinButtonEinblendenRechtsy();}else {neinButtonEinblendenLinksy();}}, 11700);
        setTimeout(() => { setTutorScreen('Neutral');setTutorText('0'); }, 12500)
        setTimeout(() => { setButtonAktiv(true); }, 12000)
    }

    const phase3_tut3 = () =>{
        setTutCounter(33);setButtonAktiv(false);
        buttonDeaktivieren();stimulusVerbergen();
        PlayAudio_p3_tut3();
        setTutorText('32');
        setTimeout(() => { setTutorScreen('Talk');}, 10)
        setTimeout(() => { setTutorScreen('Point') }, 2000)
        setTimeout(() => { setTutorScreen('Talk') }, 3500)
        setTimeout(() => { stimulusEinblenden(tutorial_Items[11],"3"); }, 3100)
        setTimeout(() => {if (rechtsy === true) {buttonAktivierenRechtsy();}else {buttonAktivierenLinksy();}}, 5500)
        setTimeout(() => { setTutorText('32b');}, 4500)
        setTimeout(() => { setTutorScreen('Neutral'); setTutorText('0') }, 7000)
        setTimeout(() => { setButtonAktiv(true); }, 7000)
    }

    const phase3_tut4 = () =>{
        setTutCounter(34);setButtonAktiv(false);
        buttonDeaktivieren();stimulusVerbergen();
        PlayAudio_p3_tut4();
        setTutorText('33');
        setTimeout(() => { setTutorScreen('Talk');}, 10)
        setTimeout(() => { setTutorScreen('Point') }, 2000)
        setTimeout(() => { setTutorScreen('Talk') }, 3500)
        setTimeout(() => { stimulusEinblenden(tutorial_Items[10],"3"); }, 2500)
        setTimeout(() => {if (rechtsy === true) {buttonAktivierenRechtsy();}else {buttonAktivierenLinksy();}}, 5300)
        setTimeout(() => { setTutorScreen('Neutral'); setTutorText('0') }, 6500)
        setTimeout(() => { setButtonAktiv(true); }, 7000)
    }

    const phase3_tut_fertig = () => {
        setTutCounter(35);
        setInTutorium(false);
        setInHilfePhase(false);
        PlayAudio_p3_tut5();
        stimulusVerbergen();
        itemTafelVerbergen();
        verbergeTargetKlein();
        buttonVerbergen();
        TargetVerbergen();
        setInTutorium(false);
        setTutorScreen('Talk');
        setTutorText('34');
        setTimeout(()=>{setTutorText('34b')},5500);
        setTimeout(()=>{setTutorText('34c')},9000);
        setTimeout(()=>{setTutorText('34d')},14000);
        setTimeout(()=>{ setTutorScreen('Loben');},14500);
        setTimeout(() => { setTutorScreen('Neutral'); setTutorText('0');setSpeakerWork('SpeakerIcon_gone');  }, 16500);
        setTimeout(()=>{startePhase1()},17000);
    }

    const phase4_tut1 =() =>{
        if(reTest == "false")
        {       
        setTutCounter(41);
        PlayAudio_p4_tut1();
        setProgressBarVisible("ProgressbarWrapper gone");
        weiterButtonDeaktivieren();
        setInTutorium(true);
        setInDiagnose(false);
        setButtonAktiv(false);
        TargetVerbergen();
        buttonDeaktivieren();
        verbergeTargetKlein();
        setInTutorium(true);
        setInDiagnose(false);
        setButtonAktiv(false);
        stimulusVerbergen();
        itemTafelVerbergen();
        buttonVerbergen();
        setTutorScreen('Loben');
        setTutorText('35');
        setSatzItemLayout('SatzItemLayout');
        set_SIL_Target(tutorial_Items[6]);
        setSIL1('SIL_TutImg1');
        setSIL2('SIL_TutImg2');
        setSIL3('SIL_TutImg3');
        setTimeout(() => {  setTutorScreen('Talk');}, 2000);
        setTimeout(() => {  setTutorScreen('Neutral');}, 4500);
        setTimeout(() => {  setTutorScreen('Talk');}, 5000);
        setTimeout(() => {  setTutorScreen('Neutral');}, 8500);
        setTimeout(() => {  setTutorScreen('Talk');}, 9000);
        setTimeout(() => {  setTutorScreen('Point');}, 11000);
        setTimeout(() => {setSIL_Textbereich('SIL_Textbereich')}, 11500);
        setTimeout(() => {  setTutorScreen('Talk');}, 12500);
        setTimeout(() => {  setTutorText('35b');}, 13000);
        setTimeout(() => {  setTutorText('0');}, 16000);
        setTimeout(() => {  setTutorScreen('Point');}, 17000);
        setTimeout(() => {setSIL_Bildbereich_BildFeld1('SIL_Bildbereich_BildFeld1')}, 17500);
        setTimeout(() => {  setTutorScreen('Talk');}, 18000);
        setTimeout(() => {  setTutorScreen('Point');}, 19000);
        setTimeout(() => {setSIL_Bildbereich_BildFeld2('SIL_Bildbereich_BildFeld2')}, 19500);
        setTimeout(() => {  setTutorScreen('Talk');}, 20000);
        setTimeout(() => {  setTutorScreen('Point');}, 21000);
        setTimeout(() => {setSIL_Bildbereich_BildFeld3('SIL_Bildbereich_BildFeld3')}, 21500);
        setTimeout(() => {  setTutorScreen('Talk');}, 22000);
        setTimeout(() => { setButtonAktiv(true); setInHilfePhase(true);}, 30000);
        setTimeout(() => { setTutorScreen('Neutral'); setTutorText('0'); }, 30000);
        }
        if(reTest == "true")
        {
            meta_phase1();
        }
    }

    const phase4_tut2 = () =>{
        setInTutorium(false);
        setSIL_Bildbereich_BildFeld1('SIL_Bildbereich_BildFeld1_gone');
        setSIL_Bildbereich_BildFeld2('SIL_Bildbereich_BildFeld2_gone');
        setSIL_Bildbereich_BildFeld3('SIL_Bildbereich_BildFeld3_gone');
        setSIL_Textbereich('SIL_Textbereich_gone');
        setTutCounter(42);
        PlayAudio_p4_tut2();
        setTutorScreen('Talk');
        setTutorText('36');
        setTimeout(() => {  setTutorText('36b');}, 10000);
        setTimeout(() => { setTutorScreen('Neutral'); setTutorText('0'); }, 11000);
        setTimeout(() => { stimulusCycle4() }, 13000);
    }
         
    
    const s_reTut1 = () =>
    {
        PlayAudio_modRe1();
        setTutCounter(80);zeigeLupeGross();
        setTimeout(() => { setTutorScreen('Talk') }, 500);
        setTimeout(() => { setTutorText('80') }, 600);
        setTimeout(() => { setTutorText('81') }, 4500);
        setTimeout(() => { setTutorText('82') }, 11000)
        setTimeout(() => { setTutorScreen('Point') }, 15800)
        setTimeout(() => { setTutorText('83') }, 16500)
        setTimeout(() => { setTutorScreen('Talk') }, 18000)
        setTimeout(() => { setTutorScreen('Point') }, 20000)
        setTimeout(() => { zeigeTargetGroß(tutorial_Items[12], "1"); }, 16000)
        setTimeout(() => { weiterButtonAktivieren(); }, 21500)
        setTimeout(() => { setTutorScreen('Neutral'); setTutorText('0') }, 23000)
        setTimeout(() => { setButtonAktiv(true); }, 24000)
    }

    const s_reTut2 = () =>
    {
        setTutCounter(81);TargetVerbergen(); weiterButtonDeaktivieren();setButtonAktiv(false);zeigeTargetKlein(tutorial_Items[12],"1");PlayAudio_mod2();setTutorText('5b'); 
        setTimeout(() => { setTutorScreen('Talk'); setTutorText('6') }, 100)
        setTimeout(() => { setTutorScreen('Point') }, 8000)
        setTimeout(() => { itemTafelEinblenden();stimulusEinblenden(tutorial_Items[12], "1"); }, 8500)
        setTimeout(() => { setTutorScreen('Talk') }, 9000)
        setTimeout(() => { setTutorText('7') }, 9500)
        setTimeout(() => { setTutorText('8') }, 21000)
        setTimeout(() => { setTutorScreen('Point') }, 18500)
        setTimeout(() => { setTutorScreen('Talk') }, 20500)
        setTimeout(() => { setTutorScreen('Point') }, 29000)
        setTimeout(() => { setTutorScreen('Talk') }, 30500)
        setTimeout(() => { setTutorScreen('Neutral'); setTutorText('0') }, 31000)
        setTimeout(() => { if (rechtsy === true) {jaButtonEinblendenRechtsy();}else {jaButtonEinblendenLinksy();}}, 21000)
        setTimeout(() => { if (rechtsy === true) {neinButtonEinblendenRechtsy();}else {neinButtonEinblendenLinksy();}}, 30000)
        setTimeout(() => { setButtonAktiv(true); }, 33000)
    }

    const s_reTut3 = () =>{
        setTutCounter(82);setButtonAktiv(false);buttonDeaktivieren();stimulusVerbergen();PlayAudio_mod3();setTutorText('8b');
        setTimeout(() => { setTutorScreen('Talk'); setTutorText('9') }, 100)
        setTimeout(() => { setTutorScreen('Point') }, 2500)
        setTimeout(() => { stimulusEinblenden(tutorial_Items[13], "1"); }, 3200)
        setTimeout(() => { setTutorScreen('Talk'); setTutorText('10') }, 4000)
        setTimeout(() => { if (rechtsy === true) {jaButtonEinblendenRechtsy();}else {jaButtonEinblendenLinksy();}}, 13800)
        setTimeout(() => {if (rechtsy === true) {neinButtonEinblendenRechtsy();}else {neinButtonEinblendenLinksy();}}, 13800)
        setTimeout(() => { setTutorScreen('Neutral'); setTutorText('0') }, 13000)
        setTimeout(() => { setButtonAktiv(true); }, 14000)
    }

    const s_reTut_finish = () =>{
        setTutCounter(90);PlayAudio_mod13();stimulusVerbergen();itemTafelVerbergen();verbergeTargetKlein();buttonVerbergen();TargetVerbergen();setInTutorium(false);
        setTimeout(() => { setTutorScreen('Loben'); setTutorText('23')}, 10)
        setTimeout(() => { setTutorScreen('Talk'); }, 2500)
        setTimeout(() => { setTutorScreen('Loben'); setTutorText('24')}, 11000)
        setTimeout(() => { setTutorScreen('Neutral'); setTutorText('0') }, 13000)
        setTimeout(() => { startePhase1(); }, 13000)
    }
    {/*Skripting*/ }

    {/*Skripting*/ }

          


    {/*Kommunikation mit dem Backend*/ }   
      
    const orderItems = () => {       
        Axios.post(Api+'getaidelitems',{testbogen: testbogen, schulerCode: schulerCode}).then((response)=>{
            if(response.data.length>30)
            {
                //console.log(response.data)
                if(testbogen == "AiDe-L-M1")
                {

                    for(let iread = 0; iread < response.data.length; iread++)
                    {
                        var itemId = response.data[iread].itemId;
                        var itemTarget = response.data[iread].target;
                        var itemtyp = response.data[iread].itemtyp;

                        if(itemId < 10800)
                        {
                            var randomStimulus = [response.data[iread].stimulus1, response.data[iread].stimulus2, response.data[iread].stimulus3, response.data[iread].stimulus4, response.data[iread].stimulus5];
                            var randomNummer = [Math.floor(Math.random() * 5), Math.floor(Math.random() * 5), Math.floor(Math.random() * 5), Math.floor(Math.random() * 5), Math.floor(Math.random() * 5)];
                            if (randomNummer[0] != 0 && randomNummer[1] != 0 && randomNummer[2] != 0 && randomNummer[3] != 0 && randomNummer[4] != 0) {
                                var zwang = Math.floor(Math.random() * 4);
                                randomNummer[zwang] = 0;}
                            var itemS1 = randomStimulus[randomNummer[0]];
                            var itemS2 = randomStimulus[randomNummer[1]];
                            var itemS3 = randomStimulus[randomNummer[2]];
                            var itemS4 = randomStimulus[randomNummer[3]];
                            var itemS5 = randomStimulus[randomNummer[4]];
                            var itemRichtigkeit = "5";
                            var item = [itemId, itemtyp, itemTarget, itemS1, itemS2, itemS3, itemS4, itemS5, itemRichtigkeit];
                        }

                        if(itemId > 10800)
                        {
                            let img = [1, 2, 3];
                            var SIL_randomNummer1 = Math.floor(Math.random() * 3);
                            var SIL_randomNummer2 = Math.floor(Math.random() * 3);
                            let SIL_safe = img[SIL_randomNummer1];
                            img[SIL_randomNummer1] = img[SIL_randomNummer2];
                            img[SIL_randomNummer2] = SIL_safe;
                            var SIL_randomNummer3 = Math.floor(Math.random() * 3);
                            var SIL_randomNummer4 = Math.floor(Math.random() * 3);
                            let SIL_safe2 = img[SIL_randomNummer3];
                            img[SIL_randomNummer3] = img[SIL_randomNummer4];
                            img[SIL_randomNummer4] = SIL_safe2;
                            var SIL_i = [itemId, itemTarget, img[0]-1, img[1]-1, img[2]-1, itemtyp];
                        }

                        if(itemId < 10200){itemGruppe0.push(item)}
                        if(itemId > 10200 && itemId < 10300){itemGruppe1.push(item)}
                        if(itemId > 10300 && itemId < 10400){itemGruppe1.push(item)}
                        if(itemId > 10400 && itemId < 10500){itemGruppe1.push(item)}
                        if(itemId > 10500 && itemId < 10600){itemGruppe1.push(item)}
                        if(itemId > 10600 && itemId < 10800){itemGruppe2.push(item)}

                        if(itemId > 10800){SIL_Item_pre_pre.push(SIL_i)}
                        
                    }
                
                for(let sortSIL = 0; sortSIL<10000; sortSIL++)
                {
                    for (let sortSIL2 = 0; sortSIL2 < SIL_Item_pre_pre.length; sortSIL2++)
                    {
                        if(SIL_Item_pre_pre[sortSIL2][0] == (sortSIL+10000))
                        {
                            SIL_Item_pre.push(SIL_Item_pre_pre[sortSIL2]);
                        }
                    }
                }

                


                for(let g0 = 0; g0 < itemGruppe0.length; g0++){itemsPre.push(itemGruppe0[g0])}
                for(let g1 = 0; g1 < itemGruppe1.length; g1++){itemsPre.push(itemGruppe1[g1])}
                for(let g2 = 0; g2 < itemGruppe2.length; g2++){itemsPre.push(itemGruppe2[g2])}
                for(let g3 = 0; g3 < itemGruppe3.length; g3++){itemsPre.push(itemGruppe3[g3])}
                
                var ran1 = [];
                for (let rn = 0; rn < response.data.length-SIL_Item_pre.length; rn++)
                {ran1.push(rn);}
                for (let rn2 = 0; rn2 < 40; rn2++)
                {
                    var randNummer1 = [Math.floor(Math.random()*4), Math.floor(Math.random()*4)];
                    var safe = ran1[randNummer1[0]];
                    ran1[randNummer1[0]] = ran1[randNummer1[1]];
                    ran1[randNummer1[1]] = safe;

                    var randNummer2 = [Math.floor(Math.random()*(itemGruppe0.length)), Math.floor(Math.random()*(itemGruppe0.length))];
                    var safe2 = ran1[randNummer2[0]];
                    ran1[randNummer2[0]] = ran1[randNummer2[1]];
                    ran1[randNummer2[1]] = safe2;

                    var randNummer3 = [Math.floor(Math.random()*itemGruppe1.length)+itemGruppe0.length, Math.floor(Math.random()*itemGruppe1.length)+itemGruppe0.length];
                    var safe3 = ran1[randNummer3[0]];
                    ran1[randNummer3[0]] = ran1[randNummer3[1]];
                    ran1[randNummer3[1]] = safe3;

                    var randNummer4 = [Math.floor(Math.random()*itemGruppe2.length)+itemGruppe0.length+itemGruppe1.length, Math.floor(Math.random()*itemGruppe2.length)+itemGruppe0.length+itemGruppe1.length];
                    var safe4 = ran1[randNummer4[0]];
                    ran1[randNummer4[0]] = ran1[randNummer4[1]];
                    ran1[randNummer4[1]] = safe4;

                    var randNummer5 = [Math.floor(Math.random()*itemGruppe3.length)+itemGruppe0.length+itemGruppe1.length+itemGruppe2.length, Math.floor(Math.random()*itemGruppe3.length)+itemGruppe0.length+itemGruppe1.length+itemGruppe2.length];
                    var safe5 = ran1[randNummer5[0]];
                    ran1[randNummer5[0]] = ran1[randNummer5[1]];
                    ran1[randNummer5[1]] = safe5;
                }

                var Sil_ran = [];
                for(let srn = 0; srn < itemAnzahlPhase4; srn++)
                {
                    Sil_ran.push(srn);
                }
                for(let srn2 = 0; srn2 < 40; srn2++)
                {
                    var Random1 = Math.floor(Math.random()*9);
                    var Random2 = Math.floor(Math.random()*9);
                    var Random3 = 9+(Math.floor(Math.random()*9));
                    var Random4 = 9+(Math.floor(Math.random()*9));

                    if(Random1 < 0){Random1 = Math.floor(Math.random()*9);}
                    if(Random2 < 0){Random2 = Math.floor(Math.random()*9);}
                    if(Random3 < 0){Random3 = 9+(Math.floor(Math.random()*9));}
                    if(Random4 < 0){Random4 = 9+(Math.floor(Math.random()*9));}

                    var SilRandNummer1 = [Random1, Random2];
                    var Sil_Safe = Sil_ran[SilRandNummer1[0]];
                    Sil_ran[SilRandNummer1[0]] = Sil_ran[SilRandNummer1[1]];
                    Sil_ran[SilRandNummer1[1]] = Sil_Safe;

                    var SilRandNummer2 = [Random3, Random4];
                    var Sil_Safe2 = Sil_ran[SilRandNummer2[0]];
                    Sil_ran[SilRandNummer2[0]] = Sil_ran[SilRandNummer2[1]];
                    Sil_ran[SilRandNummer2[1]] = Sil_Safe2;

                }

                for(let preTo = 0; preTo<response.data.length-SIL_Item_pre.length; preTo++)
                {
                    items.push(itemsPre[ran1[preTo]]);
                }

                for(let preToSIL = 0; preToSIL < itemAnzahlPhase4; preToSIL++)
                {
                    SIL_Item.push(SIL_Item_pre[Sil_ran[preToSIL]]);
                }

                //console.log(SIL_Item);


                }

                if(testbogen == "AiDe-L-M2")
                {

                    for(let iread = 0; iread < response.data.length; iread++)
                    {
                        var itemId = response.data[iread].itemId;
                        var itemTarget = response.data[iread].target;
                        var itemtyp = response.data[iread].itemtyp;

                        if(itemId < 10800)
                        {
                            var randomStimulus = [response.data[iread].stimulus1, response.data[iread].stimulus2, response.data[iread].stimulus3, response.data[iread].stimulus4, response.data[iread].stimulus5];
                            var randomNummer = [Math.floor(Math.random() * 5), Math.floor(Math.random() * 5), Math.floor(Math.random() * 5), Math.floor(Math.random() * 5), Math.floor(Math.random() * 5)];
                            if (randomNummer[0] != 0 && randomNummer[1] != 0 && randomNummer[2] != 0 && randomNummer[3] != 0 && randomNummer[4] != 0) {
                                var zwang = Math.floor(Math.random() * 4);
                                randomNummer[zwang] = 0;}
                            var itemS1 = randomStimulus[randomNummer[0]];
                            var itemS2 = randomStimulus[randomNummer[1]];
                            var itemS3 = randomStimulus[randomNummer[2]];
                            var itemS4 = randomStimulus[randomNummer[3]];
                            var itemS5 = randomStimulus[randomNummer[4]];
                            var itemRichtigkeit = "5";
                            var item = [itemId, itemtyp, itemTarget, itemS1, itemS2, itemS3, itemS4, itemS5, itemRichtigkeit];
                        }

                        if(itemId > 10800)
                        {
                            let img = [1, 2, 3];
                            var SIL_randomNummer1 = Math.floor(Math.random() * 3);
                            var SIL_randomNummer2 = Math.floor(Math.random() * 3);
                            let SIL_safe = img[SIL_randomNummer1];
                            img[SIL_randomNummer1] = img[SIL_randomNummer2];
                            img[SIL_randomNummer2] = SIL_safe;
                            var SIL_randomNummer3 = Math.floor(Math.random() * 3);
                            var SIL_randomNummer4 = Math.floor(Math.random() * 3);
                            let SIL_safe2 = img[SIL_randomNummer3];
                            img[SIL_randomNummer3] = img[SIL_randomNummer4];
                            img[SIL_randomNummer4] = SIL_safe2;
                            var SIL_i = [itemId, itemTarget, img[0]-1, img[1]-1, img[2]-1];
                        }

                        if(itemId < 10200){itemGruppe0.push(item)}
                        if(itemId > 10200 && itemId < 10300){itemGruppe1.push(item)}
                        if(itemId > 10300 && itemId < 10400){itemGruppe1.push(item)}
                        if(itemId > 10400 && itemId < 10500){itemGruppe1.push(item)}
                        if(itemId > 10500 && itemId < 10600){itemGruppe1.push(item)}
                        if(itemId > 10600 && itemId < 10800){itemGruppe2.push(item)}
                        if(itemId > 10800){SIL_Item_pre_pre.push(SIL_i)}
                        
                    }
                    for(let sortSIL = 0; sortSIL<10000; sortSIL++)
                        {
                            for (let sortSIL2 = 0; sortSIL2 < SIL_Item_pre_pre.length; sortSIL2++)
                            {
                                if(SIL_Item_pre_pre[sortSIL2][0] == (sortSIL+10000))
                                {
                                    SIL_Item_pre.push(SIL_Item_pre_pre[sortSIL2]);
                                }
                            }
                        }

                for(let g0 = 0; g0 < itemGruppe0.length; g0++){itemsPre.push(itemGruppe0[g0])}
                for(let g1 = 0; g1 < itemGruppe1.length; g1++){itemsPre.push(itemGruppe1[g1])}
                for(let g2 = 0; g2 < itemGruppe2.length; g2++){itemsPre.push(itemGruppe2[g2])}
                for(let g3 = 0; g3 < itemGruppe3.length; g3++){itemsPre.push(itemGruppe3[g3])}
                
                var ran1 = [];
                for (let rn = 0; rn < response.data.length-SIL_Item_pre.length; rn++)
                {ran1.push(rn);}
                for (let rn2 = 0; rn2 < 40; rn2++)
                {
                    var randNummer1 = [Math.floor(Math.random()*4), Math.floor(Math.random()*4)];
                    var safe = ran1[randNummer1[0]];
                    ran1[randNummer1[0]] = ran1[randNummer1[1]];
                    ran1[randNummer1[1]] = safe;

                    var randNummer2 = [Math.floor(Math.random()*(itemGruppe0.length)), Math.floor(Math.random()*(itemGruppe0.length))];
                    var safe2 = ran1[randNummer2[0]];
                    ran1[randNummer2[0]] = ran1[randNummer2[1]];
                    ran1[randNummer2[1]] = safe2;

                    var randNummer3 = [Math.floor(Math.random()*itemGruppe1.length)+itemGruppe0.length, Math.floor(Math.random()*itemGruppe1.length)+itemGruppe0.length];
                    var safe3 = ran1[randNummer3[0]];
                    ran1[randNummer3[0]] = ran1[randNummer3[1]];
                    ran1[randNummer3[1]] = safe3;

                    var randNummer4 = [Math.floor(Math.random()*itemGruppe2.length)+itemGruppe0.length+itemGruppe1.length, Math.floor(Math.random()*itemGruppe2.length)+itemGruppe0.length+itemGruppe1.length];
                    var safe4 = ran1[randNummer4[0]];
                    ran1[randNummer4[0]] = ran1[randNummer4[1]];
                    ran1[randNummer4[1]] = safe4;

                    var randNummer5 = [Math.floor(Math.random()*itemGruppe3.length)+itemGruppe0.length+itemGruppe1.length+itemGruppe2.length, Math.floor(Math.random()*itemGruppe3.length)+itemGruppe0.length+itemGruppe1.length+itemGruppe2.length];
                    var safe5 = ran1[randNummer5[0]];
                    ran1[randNummer5[0]] = ran1[randNummer5[1]];
                    ran1[randNummer5[1]] = safe5;
                }
                
                for(let preTo = 0; preTo<response.data.length-SIL_Item_pre.length; preTo++)
                {
                    items.push(itemsPre[ran1[preTo]]);
                }
                }

                if(testbogen == "AiDe-L-G1")
                {

                    for(let iread = 0; iread < response.data.length; iread++)
                    {
                        var itemId = response.data[iread].itemId;
                        var itemTarget = response.data[iread].target;
                        var itemtyp = response.data[iread].itemtyp;
                       
                        if(itemId < 10800)
                        {
                            var randomStimulus = [response.data[iread].stimulus1, response.data[iread].stimulus2, response.data[iread].stimulus3, response.data[iread].stimulus4, response.data[iread].stimulus5];
                            var randomNummer = [Math.floor(Math.random() * 5), Math.floor(Math.random() * 5), Math.floor(Math.random() * 5), Math.floor(Math.random() * 5), Math.floor(Math.random() * 5)];
                            if (randomNummer[0] != 0 && randomNummer[1] != 0 && randomNummer[2] != 0 && randomNummer[3] != 0 && randomNummer[4] != 0) {
                                var zwang = Math.floor(Math.random() * 4);
                                randomNummer[zwang] = 0;}
                            var itemS1 = randomStimulus[randomNummer[0]];
                            var itemS2 = randomStimulus[randomNummer[1]];
                            var itemS3 = randomStimulus[randomNummer[2]];
                            var itemS4 = randomStimulus[randomNummer[3]];
                            var itemS5 = randomStimulus[randomNummer[4]];
                            var itemRichtigkeit = "5";
                            var item = [itemId, itemtyp, itemTarget, itemS1, itemS2, itemS3, itemS4, itemS5, itemRichtigkeit];
                        }

                        if(itemId > 20200)
                        {
                            var randomStimulus = [response.data[iread].stimulus1, response.data[iread].stimulus2, response.data[iread].stimulus3, response.data[iread].stimulus4, response.data[iread].stimulus5];
                            var randomNummer = [Math.floor(Math.random() * 5), Math.floor(Math.random() * 5), Math.floor(Math.random() * 5), Math.floor(Math.random() * 5), Math.floor(Math.random() * 5)];
                            if (randomNummer[0] != 0 && randomNummer[1] != 0 && randomNummer[2] != 0 && randomNummer[3] != 0 && randomNummer[4] != 0) {
                                var zwang = Math.floor(Math.random() * 4);
                                randomNummer[zwang] = 0;}
                            var itemS1 = randomStimulus[randomNummer[0]];
                            var itemS2 = randomStimulus[randomNummer[1]];
                            var itemS3 = randomStimulus[randomNummer[2]];
                            var itemS4 = randomStimulus[randomNummer[3]];
                            var itemS5 = randomStimulus[randomNummer[4]];
                            var itemRichtigkeit = "5";
                            var item = [itemId, itemtyp, itemTarget, itemS1, itemS2, itemS3, itemS4, itemS5, itemRichtigkeit];
                        }

                        if(itemId > 10800 && itemId < 20000)
                        {
                            let img = [1, 2, 3];
                            var SIL_randomNummer1 = Math.floor(Math.random() * 3);
                            var SIL_randomNummer2 = Math.floor(Math.random() * 3);
                            let SIL_safe = img[SIL_randomNummer1];
                            img[SIL_randomNummer1] = img[SIL_randomNummer2];
                            img[SIL_randomNummer2] = SIL_safe;
                            var SIL_randomNummer3 = Math.floor(Math.random() * 3);
                            var SIL_randomNummer4 = Math.floor(Math.random() * 3);
                            let SIL_safe2 = img[SIL_randomNummer3];
                            img[SIL_randomNummer3] = img[SIL_randomNummer4];
                            img[SIL_randomNummer4] = SIL_safe2;
                            var SIL_i = [itemId, itemTarget, img[0]-1, img[1]-1, img[2]-1];
                        }

                        if(itemId < 10200){itemGruppe0.push(item)}
                        if(itemId < 10200){itemGruppe0.push(item)}
                        if(itemId > 10200 && itemId < 10300){itemGruppe1.push(item)}
                        if(itemId > 10300 && itemId < 10400){itemGruppe1.push(item)}
                        if(itemId > 10400 && itemId < 10500){itemGruppe1.push(item)}
                        if(itemId > 10500 && itemId < 10600){itemGruppe1.push(item)}
                        if(itemId > 10600 && itemId < 10800){itemGruppe2.push(item)}

                        if(itemId > 10800){SIL_Item_pre_pre.push(SIL_i)}

                        for(let sortSIL = 0; sortSIL<10000; sortSIL++)
                            {
                                for (let sortSIL2 = 0; sortSIL2 < SIL_Item_pre_pre.length; sortSIL2++)
                                {
                                    if(SIL_Item_pre_pre[sortSIL2][0] == (sortSIL+10000))
                                    {
                                        SIL_Item_pre.push(SIL_Item_pre_pre[sortSIL2]);
                                    }
                                }
                            }
                        
                    }
                for(let g0 = 0; g0 < itemGruppe0.length; g0++){itemsPre.push(itemGruppe0[g0])}
                for(let g1 = 0; g1 < itemGruppe1.length; g1++){itemsPre.push(itemGruppe1[g1])}
                
                
                var ran1 = [];
                for (let rn = 0; rn < response.data.length-SIL_Item_pre.length; rn++)
                {ran1.push(rn);}
                for (let rn2 = 0; rn2 < 40; rn2++)
                {
                    var randNummer1 = [Math.floor(Math.random()*4), Math.floor(Math.random()*4)];
                    var safe = ran1[randNummer1[0]];
                    ran1[randNummer1[0]] = ran1[randNummer1[1]];
                    ran1[randNummer1[1]] = safe;

                    var randNummer2 = [Math.floor(Math.random()*(itemGruppe0.length)), Math.floor(Math.random()*(itemGruppe0.length))];
                    var safe2 = ran1[randNummer2[0]];
                    ran1[randNummer2[0]] = ran1[randNummer2[1]];
                    ran1[randNummer2[1]] = safe2;

                    var randNummer3 = [Math.floor(Math.random()*itemGruppe1.length)+itemGruppe0.length, Math.floor(Math.random()*itemGruppe1.length)+itemGruppe0.length];
                    var safe3 = ran1[randNummer3[0]];
                    ran1[randNummer3[0]] = ran1[randNummer3[1]];
                    ran1[randNummer3[1]] = safe3;

                    var randNummer4 = [Math.floor(Math.random()*itemGruppe2.length)+itemGruppe0.length+itemGruppe1.length, Math.floor(Math.random()*itemGruppe2.length)+itemGruppe0.length+itemGruppe1.length];
                    var safe4 = ran1[randNummer4[0]];
                    ran1[randNummer4[0]] = ran1[randNummer4[1]];
                    ran1[randNummer4[1]] = safe4;

                    var randNummer5 = [Math.floor(Math.random()*itemGruppe3.length)+itemGruppe0.length+itemGruppe1.length+itemGruppe2.length, Math.floor(Math.random()*itemGruppe3.length)+itemGruppe0.length+itemGruppe1.length+itemGruppe2.length];
                    var safe5 = ran1[randNummer5[0]];
                    ran1[randNummer5[0]] = ran1[randNummer5[1]];
                    ran1[randNummer5[1]] = safe5;
                }
                
                for(let preTo = 0; preTo<itemsPre.length; preTo++)
                {
                    items.push(itemsPre[ran1[preTo]]);
                }
                //console.log(items);
                //log(SIL_Item_pre);
                }

                
                if(testbogen == "AiDe-L-S1")
                {

                    for(let iread = 0; iread < response.data.length; iread++)
                    {
                        var itemId = response.data[iread].itemId;
                        var itemTarget = response.data[iread].target;
                        var itemtyp = response.data[iread].itemtyp;
                       
                        if(itemId < 10800)
                        {
                            var randomStimulus = [response.data[iread].stimulus1, response.data[iread].stimulus2, response.data[iread].stimulus3, response.data[iread].stimulus4, response.data[iread].stimulus5];
                            var randomNummer = [Math.floor(Math.random() * 5), Math.floor(Math.random() * 5), Math.floor(Math.random() * 5), Math.floor(Math.random() * 5), Math.floor(Math.random() * 5)];
                            if (randomNummer[0] != 0 && randomNummer[1] != 0 && randomNummer[2] != 0 && randomNummer[3] != 0 && randomNummer[4] != 0) {
                                var zwang = Math.floor(Math.random() * 4);
                                randomNummer[zwang] = 0;}
                            var itemS1 = randomStimulus[randomNummer[0]];
                            var itemS2 = randomStimulus[randomNummer[1]];
                            var itemS3 = randomStimulus[randomNummer[2]];
                            var itemS4 = randomStimulus[randomNummer[3]];
                            var itemS5 = randomStimulus[randomNummer[4]];
                            var itemRichtigkeit = "5";
                            var item = [itemId, itemtyp, itemTarget, itemS1, itemS2, itemS3, itemS4, itemS5, itemRichtigkeit];
                        }

                        if(itemId > 20200)
                        {
                            var randomStimulus = [response.data[iread].stimulus1, response.data[iread].stimulus2, response.data[iread].stimulus3, response.data[iread].stimulus4, response.data[iread].stimulus5];
                            var randomNummer = [Math.floor(Math.random() * 5), Math.floor(Math.random() * 5), Math.floor(Math.random() * 5), Math.floor(Math.random() * 5), Math.floor(Math.random() * 5)];
                            if (randomNummer[0] != 0 && randomNummer[1] != 0 && randomNummer[2] != 0 && randomNummer[3] != 0 && randomNummer[4] != 0) {
                                var zwang = Math.floor(Math.random() * 4);
                                randomNummer[zwang] = 0;}
                            var itemS1 = randomStimulus[randomNummer[0]];
                            var itemS2 = randomStimulus[randomNummer[1]];
                            var itemS3 = randomStimulus[randomNummer[2]];
                            var itemS4 = randomStimulus[randomNummer[3]];
                            var itemS5 = randomStimulus[randomNummer[4]];
                            var itemRichtigkeit = "5";
                            var item = [itemId, itemtyp, itemTarget, itemS1, itemS2, itemS3, itemS4, itemS5, itemRichtigkeit];
                        }

                        if(itemId > 10800 && itemId < 20000)
                        {
                            let img = [1, 2, 3];
                            var SIL_randomNummer1 = Math.floor(Math.random() * 3);
                            var SIL_randomNummer2 = Math.floor(Math.random() * 3);
                            let SIL_safe = img[SIL_randomNummer1];
                            img[SIL_randomNummer1] = img[SIL_randomNummer2];
                            img[SIL_randomNummer2] = SIL_safe;
                            var SIL_randomNummer3 = Math.floor(Math.random() * 3);
                            var SIL_randomNummer4 = Math.floor(Math.random() * 3);
                            let SIL_safe2 = img[SIL_randomNummer3];
                            img[SIL_randomNummer3] = img[SIL_randomNummer4];
                            img[SIL_randomNummer4] = SIL_safe2;
                            var SIL_i = [itemId, itemTarget, img[0]-1, img[1]-1, img[2]-1];
                        }

                        if(itemId < 10200){itemGruppe0.push(item)}
                        if(itemId > 10200 && itemId < 10300){itemGruppe1.push(item)}
                        if(itemId > 10300 && itemId < 10400){itemGruppe1.push(item)}
                        if(itemId > 10400 && itemId < 10500){itemGruppe1.push(item)}
                        if(itemId > 10500 && itemId < 10600){itemGruppe1.push(item)}
                        if(itemId > 10600 && itemId < 10800){itemGruppe2.push(item)}

                        if(itemId > 10800){SIL_Item_pre_pre.push(SIL_i)}

                        for(let sortSIL = 0; sortSIL<10000; sortSIL++)
                            {
                                for (let sortSIL2 = 0; sortSIL2 < SIL_Item_pre_pre.length; sortSIL2++)
                                {
                                    if(SIL_Item_pre_pre[sortSIL2][0] == (sortSIL+10000))
                                    {
                                        SIL_Item_pre.push(SIL_Item_pre_pre[sortSIL2]);
                                    }
                                }
                            }
                        
                    }
                //console.log(itemGruppe0);
                //console.log(itemGruppe1);
                for(let g0 = 0; g0 < itemGruppe0.length; g0++){itemsPre.push(itemGruppe0[g0])}
                for(let g1 = 0; g1 < itemGruppe1.length; g1++){itemsPre.push(itemGruppe1[g1])}
                
                
                var ran1 = [];
                for (let rn = 0; rn < itemsPre.length; rn++)
                {ran1.push(rn);}
                for (let rn2 = 0; rn2 < 40; rn2++)
                {
                    var randNummer1 = [Math.floor(Math.random()*4), Math.floor(Math.random()*4)];
                    var safe = ran1[randNummer1[0]];
                    ran1[randNummer1[0]] = ran1[randNummer1[1]];
                    ran1[randNummer1[1]] = safe;

                    var randNummer2 = [Math.floor(Math.random()*(itemGruppe0.length)), Math.floor(Math.random()*(itemGruppe0.length))];
                    var safe2 = ran1[randNummer2[0]];
                    ran1[randNummer2[0]] = ran1[randNummer2[1]];
                    ran1[randNummer2[1]] = safe2;

                    var randNummer3 = [Math.floor(Math.random()*itemGruppe1.length)+itemGruppe0.length, Math.floor(Math.random()*itemGruppe1.length)+itemGruppe0.length];
                    var safe3 = ran1[randNummer3[0]];
                    ran1[randNummer3[0]] = ran1[randNummer3[1]];
                    ran1[randNummer3[1]] = safe3;                    
                }
                
                for(let preTo = 0; preTo<itemsPre.length; preTo++)
                {
                    items.push(itemsPre[ran1[preTo]]);
                }
                //console.log(items);
                //console.log(SIL_Item_pre);
                }

                if(testbogen == "AiDe-L-W1")
                {

                    for(let iread = 0; iread < response.data.length; iread++)
                    {
                        var itemId = response.data[iread].itemId;
                        var itemTarget = response.data[iread].target;
                        var itemtyp = response.data[iread].itemtyp;
                       
                        if(itemId < 10800)
                        {
                            var randomStimulus = [response.data[iread].stimulus1, response.data[iread].stimulus2, response.data[iread].stimulus3, response.data[iread].stimulus4, response.data[iread].stimulus5];
                            var randomNummer = [Math.floor(Math.random() * 5), Math.floor(Math.random() * 5), Math.floor(Math.random() * 5), Math.floor(Math.random() * 5), Math.floor(Math.random() * 5)];
                            if (randomNummer[0] != 0 && randomNummer[1] != 0 && randomNummer[2] != 0 && randomNummer[3] != 0 && randomNummer[4] != 0) {
                                var zwang = Math.floor(Math.random() * 4);
                                randomNummer[zwang] = 0;}
                            var itemS1 = randomStimulus[randomNummer[0]];
                            var itemS2 = randomStimulus[randomNummer[1]];
                            var itemS3 = randomStimulus[randomNummer[2]];
                            var itemS4 = randomStimulus[randomNummer[3]];
                            var itemS5 = randomStimulus[randomNummer[4]];
                            var itemRichtigkeit = "5";
                            var item = [itemId, itemtyp, itemTarget, itemS1, itemS2, itemS3, itemS4, itemS5, itemRichtigkeit];
                        }

                        if(itemId > 20200)
                        {
                            var randomStimulus = [response.data[iread].stimulus1, response.data[iread].stimulus2, response.data[iread].stimulus3, response.data[iread].stimulus4, response.data[iread].stimulus5];
                            var randomNummer = [Math.floor(Math.random() * 5), Math.floor(Math.random() * 5), Math.floor(Math.random() * 5), Math.floor(Math.random() * 5), Math.floor(Math.random() * 5)];
                            if (randomNummer[0] != 0 && randomNummer[1] != 0 && randomNummer[2] != 0 && randomNummer[3] != 0 && randomNummer[4] != 0) {
                                var zwang = Math.floor(Math.random() * 4);
                                randomNummer[zwang] = 0;}
                            var itemS1 = randomStimulus[randomNummer[0]];
                            var itemS2 = randomStimulus[randomNummer[1]];
                            var itemS3 = randomStimulus[randomNummer[2]];
                            var itemS4 = randomStimulus[randomNummer[3]];
                            var itemS5 = randomStimulus[randomNummer[4]];
                            var itemRichtigkeit = "5";
                            var item = [itemId, itemtyp, itemTarget, itemS1, itemS2, itemS3, itemS4, itemS5, itemRichtigkeit];
                        }

                        if(itemId > 10800 && itemId < 20000)
                        {
                            let img = [1, 2, 3];
                            var SIL_randomNummer1 = Math.floor(Math.random() * 3);
                            var SIL_randomNummer2 = Math.floor(Math.random() * 3);
                            let SIL_safe = img[SIL_randomNummer1];
                            img[SIL_randomNummer1] = img[SIL_randomNummer2];
                            img[SIL_randomNummer2] = SIL_safe;
                            var SIL_randomNummer3 = Math.floor(Math.random() * 3);
                            var SIL_randomNummer4 = Math.floor(Math.random() * 3);
                            let SIL_safe2 = img[SIL_randomNummer3];
                            img[SIL_randomNummer3] = img[SIL_randomNummer4];
                            img[SIL_randomNummer4] = SIL_safe2;
                            var SIL_i = [itemId, itemTarget, img[0]-1, img[1]-1, img[2]-1];
                        }

                        if(itemId < 10200){itemGruppe0.push(item)}
                        if(itemId > 10200 && itemId < 10300){itemGruppe1.push(item)}
                        if(itemId > 10300 && itemId < 10400){itemGruppe1.push(item)}
                        if(itemId > 10400 && itemId < 10500){itemGruppe1.push(item)}
                        if(itemId > 10500 && itemId < 10600){itemGruppe1.push(item)}
                        if(itemId > 10600 && itemId < 10800){itemGruppe2.push(item)}

                        if(itemId > 10800){SIL_Item_pre_pre.push(SIL_i)}

                        for(let sortSIL = 0; sortSIL<10000; sortSIL++)
                            {
                                for (let sortSIL2 = 0; sortSIL2 < SIL_Item_pre_pre.length; sortSIL2++)
                                {
                                    if(SIL_Item_pre_pre[sortSIL2][0] == (sortSIL+10000))
                                    {
                                        SIL_Item_pre.push(SIL_Item_pre_pre[sortSIL2]);
                                    }
                                }
                            }
                        
                    }
                //console.log(itemGruppe0);
                //console.log(itemGruppe1);
                for(let g0 = 0; g0 < itemGruppe0.length; g0++){itemsPre.push(itemGruppe0[g0])}
                for(let g1 = 0; g1 < itemGruppe1.length; g1++){itemsPre.push(itemGruppe1[g1])}
                for(let g3 = 0; g3 < itemGruppe3.length; g3++){itemsPre.push(itemGruppe3[g3])}
                
                
                var ran1 = [];
                for (let rn = 0; rn < itemsPre.length; rn++)
                {ran1.push(rn);}
                for (let rn2 = 0; rn2 < 40; rn2++)
                {
                    var randNummer1 = [Math.floor(Math.random()*4), Math.floor(Math.random()*4)];
                    var safe = ran1[randNummer1[0]];
                    ran1[randNummer1[0]] = ran1[randNummer1[1]];
                    ran1[randNummer1[1]] = safe;

                    var randNummer2 = [Math.floor(Math.random()*(itemGruppe0.length)), Math.floor(Math.random()*(itemGruppe0.length))];
                    var safe2 = ran1[randNummer2[0]];
                    ran1[randNummer2[0]] = ran1[randNummer2[1]];
                    ran1[randNummer2[1]] = safe2;

                    var randNummer3 = [Math.floor(Math.random()*itemGruppe1.length)+itemGruppe0.length, Math.floor(Math.random()*itemGruppe1.length)+itemGruppe0.length];
                    var safe3 = ran1[randNummer3[0]];
                    ran1[randNummer3[0]] = ran1[randNummer3[1]];
                    ran1[randNummer3[1]] = safe3;                    
                }
                
                for(let preTo = 0; preTo<itemsPre.length; preTo++)
                {
                    items.push(itemsPre[ran1[preTo]]);
                }
                //console.log(items);
                //console.log(_pre);
                }


                if(testbogen != "null")
                {
                    setStartButton('StartButton');
                    setTicketMonitor("TicketMonitor"); 
                }
                setFullScreen();
                setItemsDa(true);  
                setTicketMonitor("TicketMonitor");    
            }})}
    

    const ergebnisseSenden = () => { 
        setTimeout(() => {
            Axios.post(Api+'aideergebnisse', {
                Modus: modus,
                Nutzercode: userCode,
                SchulerCode: schulerCode,
                SchulerKlasse: schulerKlasse,
                TestTicket: testTicket,
                Testbogen: testbogen,
                BenutzteItems: itemsUsed,
                BenutzteTargets: targetUsed,
                S1benutzt: s1Used,
                S2benutzt: s2Used,
                S3benutzt: s3Used,
                S4benutzt: s4Used,
                TargetDelta: targetDelta,
                S1Delta: s1Delta,
                S2Delta: s2Delta,
                S3Delta: s3Delta,
                S4Delta: s4Delta,
                S1Antwort: s1Antwort,
                S2Antwort: s2Antwort,
                S3Antwort: s3Antwort,
                S4Antwort: s4Antwort,
                Umfang: slsCounter,
                BenutzteSatze: SIL_used,
                BenutzerText: SIL_text_used,
                SatzDelta: SIL_Delta,
                SatzRichtigkeit: SIL_korrekt,
                KlassenName: KlassenName})},1000);

            if(connect == "online")
            {
                setTimeout(() => {Axios.post(Api+'AddSchulerTicket', {schulercode: schulerCode, ticket: testTicket});},2000) 
            }

            if(connect == "offline")
            {
               setTimeout(() => {Axios.post(Api+'addSchuler2', {useruid: userCode, klasse: KlassenName, schulercode: schulerCode, testticket: testTicket});},2000)
            }
                              
        
            } 
    const Kindanlegen = () =>{
        Axios.post(Api+'saveKind', {Schulnummer: userCode, Kindername: Kindername, Kindercode: schulerCode, Klasse: KlassenName})
    }

    const DiagnoseAuswerten = () =>{
        Axios.post(Api+'PrepareSingleDiagnose', {schulerCode: schulerCode, ticket: testTicket, userCode:userCode, klassenname: KlassenName})
    }
    
    {/*Kommunikation mit dem Backend*/ }

    const setFullScreen = () =>{
        const element = document.documentElement;

        if (element.requestFullscreen) {
          element.requestFullscreen().catch(err => err);
        } else if (element.webkitRequestFullscreen) {
          element.webkitRequestFullscreen().catch(err => err);
        }
      }

      const blackOut = () => {
            window.opener =null;
            window.open("", "_self");
            window.close();
      }

      const setSpracheDeutsch = () =>{
        if(buttonAktiv)
        {
            set_Meta_Familiensprache('Deutsch');
            setMetaSeite_Seite1('MetaSeite_Seite1_gone');
            setMetaSeite_Seite3('MetaSeite_Seite3');
            meta_phase3();
            setButtonAktiv(false);
        }
        
      }

      const setAndereSprache = () =>{
        if(buttonAktiv)
        {
        setMetaSeite_Seite1('MetaSeite_Seite1_gone');
        setMetaSeite_Seite2('MetaSeite_Seite2');
        meta_phase2();
        setButtonAktiv(false);
        }
      }

      const setSprache = (sprache) =>{
        if(buttonAktiv)
        {
        set_Meta_Familiensprache(sprache);
        setMetaSeite_Seite2('MetaSeite_Seite2_gone');
        setMetaSeite_Seite3('MetaSeite_Seite3');
        meta_phase3();
        setButtonAktiv(false);
        }
      }

      const setLesemedien = (wert) =>{
        if(buttonAktiv)
        {
        set_Meta_Lesemedien(wert);
        setMetaSeite_Seite3('MetaSeite_Seite3_gone');
        setMetaSeite_Seite5('MetaSeite_Seite5');
        meta_phase5();
        setButtonAktiv(false);
        }
      }

      const setGender = (gender) =>{
        if(buttonAktiv)
        {
        set_Meta_Gender(gender);
        setMetaSeite_Seite5('MetaSeite_Seite5_gone');
        setMetaSeite_Seite4('MetaSeite_Seite4');
        meta_phase4();
        setButtonAktiv(false);
        }
      }

      const setLeseZeit = (zeitumfang) =>{
        if(buttonAktiv)
        {
            set_Meta_Lesezeit(zeitumfang);
            setMetaSeite('MetaSeite_gone');
            //console.log("META-DATEN:")
            if(DemoModus==true)
            {
                DiagnoseBeenden();
            }
            if(DemoModus==false)
            {
            setTimeout(() => {
                console.log('Familiensprach: '+Meta_Familiensprache);
                console.log('Lesemedien: '+Meta_Lesemedien);
                console.log('Lesezeit: '+zeitumfang);
                Axios.post(Api+'metadaten', {userId: userCode, schulerId: schulerCode, klassenstufe: schulerKlasse, handigkeit: Meta_Handigkeit, familiensprache: Meta_Familiensprache, lesemedien: Meta_Lesemedien, gender: Meta_Gender, lesezeit: zeitumfang})
                DiagnoseBeenden();
            }, 500)
            }
        }

                   
            
      }

      const handle_LeseMedien0 = () => {setLesemedien(0)};
      const handle_LeseMedien1 = () => {setLesemedien(1)};
      const handle_LeseMedien2 = () => {setLesemedien(2)};
      const handle_LeseMedien3 = () => {setLesemedien(3)};

      const handle_Englisch = () =>{setSprache("Englisch");}
      const handle_Franzosisch = () =>{setSprache("Franzoesisch");}
      const handle_Turkisch = () =>{setSprache("Tuerkisch");}
      const handle_Arabisch = () =>{setSprache("Arabisch");}
      const handle_Kurdisch = () =>{setSprache("Kurdisch");}
      const handle_Ukrainisch = () =>{setSprache("Ukrainisch");}
      const handle_Russisch = () =>{setSprache("Russisch");}
      const handle_Andere = () =>{setSprache("Andere");}

      const handle_Lesezeit0 = () => {setLeseZeit(0)};
      const handle_Lesezeit1 = () => {setLeseZeit(1)};
      const handle_Lesezeit2 = () => {setLeseZeit(2)};
      const handle_Lesezeit3 = () => {setLeseZeit(3)};

      const handle_GenderFemale = () =>{setGender(0)};
      const handle_GenderMale = () =>{setGender(1)};

      const meta_phase1 = () =>{
            if(DemoModus == true)
            {
                console.log("Demo Modus!");
                setSIL_Textbereich('SIL_Textbereich_gone');
                itemTafelVerbergen();
                stimulusVerbergen();
                buttonVerbergen();
                verbergeTargetKlein();
                setTimeout(() => { setTutorScreen('Loben') }, 10)
                setTimeout(() => { setTutorScreen('Talk') }, 2000)
                setTimeout(() => { setTutorText('100') }, 600)
                setTimeout(() => { setTutorText('100b') }, 8000)
                setSzene('diagnoseszene');
                startSeiteAusblenden();
                diagnoseSeiteEinblenden();
                setMetaSeite('MetaSeite');
                PlayAudio_aud_meta1();
                setTimeout(() => { setTutorScreen('Point') }, 18000)
                setTimeout(() => {set_MetaS1_Deutsch('Meta_S1_Flagge_D')}, 19000)
                setTimeout(() => { setTutorScreen('Talk') }, 19500)
                setTimeout(() => { setTutorScreen('Point') }, 24000)
                setTimeout(() => {set_MetaS1_Andere('Meta_S1_Flagge_Andere')},25000)
                setTimeout(() => { setTutorScreen('Talk') }, 25500)
                setTimeout(() => { setTutorScreen('Neutral') }, 26000)
                setTimeout(() => {setButtonAktiv(true); },27000)
            }
            if(DemoModus == false)
            {          
            ergebnisseSenden();
            if(MetaDa==false)
            {
                setSIL_Textbereich('SIL_Textbereich_gone');
                itemTafelVerbergen();
                stimulusVerbergen();
                buttonVerbergen();
                verbergeTargetKlein();
                setTimeout(() => { setTutorScreen('Loben') }, 10)
                setTimeout(() => { setTutorScreen('Talk') }, 2000)
                setTimeout(() => { setTutorText('100') }, 600)
                setTimeout(() => { setTutorText('100b') }, 8000)
                setSzene('diagnoseszene');
                startSeiteAusblenden();
                diagnoseSeiteEinblenden();
                setMetaSeite('MetaSeite');
                PlayAudio_aud_meta1();
                setTimeout(() => { setTutorScreen('Point') }, 18000)
                setTimeout(() => {set_MetaS1_Deutsch('Meta_S1_Flagge_D')}, 19000)
                setTimeout(() => { setTutorScreen('Talk') }, 19500)
                setTimeout(() => { setTutorScreen('Point') }, 24000)
                setTimeout(() => {set_MetaS1_Andere('Meta_S1_Flagge_Andere')},25000)
                setTimeout(() => { setTutorScreen('Talk') }, 25500)
                setTimeout(() => { setTutorScreen('Neutral') }, 26000)
                setTimeout(() => {setButtonAktiv(true); },27000)
            }
            if(MetaDa==true)
            {
                DiagnoseBeenden();
            }
        }
           
      }

      const meta_phase2 = () =>{
        PlayAudio_aud_meta2();
        setTutorText('0');
        setTimeout(() => { setTutorScreen('Talk') }, 1000)
        setTimeout(() => { setTutorText('101') }, 1000)
        setTimeout(() => { setTutorScreen('Point') }, 7000)
        setTimeout(() => {setMetaS2_R1('Meta_Seite2_Reihe')}, 8000)
        setTimeout(() => { setTutorScreen('Talk') }, 8500)
        setTimeout(() => { setTutorScreen('Point') }, 9000)
        setTimeout(() => {setMetaS2_R2('Meta_Seite2_Reihe')}, 10000)
        setTimeout(() => { setTutorScreen('Talk') }, 10500)
        setTimeout(() => { setTutorScreen('Point') }, 11000)
        setTimeout(() => {setMetaS2_R3('Meta_Seite2_Reihe')}, 12000)
        setTimeout(() => { setTutorScreen('Talk') }, 12500)
        setTimeout(() => { setTutorScreen('Point') }, 13000)
        setTimeout(() => {setMetaS2_R4('Meta_Seite2_Reihe')}, 14000)
        setTimeout(() => { setTutorScreen('Talk') }, 14500)
        setTimeout(() => { setTutorScreen('Point') }, 15500)
        setTimeout(() => {setMetaS2_R5('Meta_Seite2_Reihe')}, 16000)
        setTimeout(() => { setTutorScreen('Talk') }, 16500)
        setTimeout(() => { setTutorScreen('Point') }, 17500)
        setTimeout(() => {setMetaS2_R6('Meta_Seite2_Reihe')}, 18000)
        setTimeout(() => { setTutorScreen('Talk') }, 18500)
        setTimeout(() => { setTutorScreen('Point') }, 19500)
        setTimeout(() => {setMetaS2_R7('Meta_Seite2_Reihe')}, 20000)
        setTimeout(() => { setTutorScreen('Talk') }, 20500)
        setTimeout(() => { setTutorScreen('Point') }, 21000)
        setTimeout(() => {setMetaS2_R8('Meta_Seite2_Reihe')}, 22000)
        setTimeout(() => { setTutorScreen('Talk') }, 22500)
        setTimeout(() => { setTutorScreen('Neutral') }, 24000)
        setTimeout(() => {setButtonAktiv(true); },25000)
      }

      const meta_phase3 = () =>{
        PlayAudio_aud_meta3();
        setTutorText('0');
        setTimeout(() => { setTutorScreen('Talk') }, 1000)
        setTimeout(() => { setTutorText('102') }, 1000)
        setTimeout(() => {setMetaS3_B1('Meta_Seite2_Button')},12500)
        setTimeout(() => {setMetaS3_B2('Meta_Seite2_Button')},14000)
        setTimeout(() => {setMetaS3_B3('Meta_Seite2_Button')},16000)
        setTimeout(() => {setMetaS3_B4('Meta_Seite2_Button')},18000)
        setTimeout(() => { setTutorScreen('Neutral') }, 19000)
        setTimeout(() => {setButtonAktiv(true); },20000)
      }

      const meta_phase4 = () =>{
        PlayAudio_aud_meta4();
        setTutorText('0');
        setTimeout(() => { setTutorScreen('Talk') }, 1000)
        setTimeout(() => { setTutorText('103') }, 1000)
        setTimeout(() => {setMetaS4_B1('Meta_Seite2_Button')},10000)
        setTimeout(() => {setMetaS4_B2('Meta_Seite2_Button')},12000)
        setTimeout(() => {setMetaS4_B3('Meta_Seite2_Button')},13000)
        setTimeout(() => {setMetaS4_B4('Meta_Seite2_Button')},15000)
        setTimeout(() => {setButtonAktiv(true); },17000)
      }

      const meta_phase5 = () =>{
        PlayAudio_aud_meta5();
        setTutorText('0');
        setTimeout(() => { setTutorScreen('Talk') }, 1000)
        setTimeout(() => { setTutorText('104') }, 1000)
        setTimeout(() => {setMetaS5_B1('Meta_Seite5_Button')},6000)
        setTimeout(() => {setMetaS5_B2('Meta_Seite5_Button')},8500)
        setTimeout(() => {setButtonAktiv(true); },9500)
      }

      const setAdminToolPageVisible = () =>{SetAdminToolPage("AdminToolPage");}
      const closeAdminToolPage = () =>{SetAdminToolPage("AdminToolPage gone");}

      const confirm_Result = () =>{
        Axios.post(Api+'confirmData', {schulerCode: schulerCode, schulerTicket: testTicket}).then((response) => {
            if(response.data.confirmation == "Roger Roger!")
            {
                if(connect == "online")
                {
                    set_End_Panel_Fill('End_Panel_Half');
                    confirm_Diagnosis();
                }

                if(connect == "offline")
                {
                    set_End_Panel_Fill('End_Panel_Success');
                    setEnd_Panel_EndButton('End_Panel_EndButton');
                }
            }

            if(response.data.confirmation == "Something went wrong!")
            {
                console.log("Resending!");
                ergebnisseSenden();
                setTimeout(()=>{confirm_Result()}, [20000])
            }
        })
        .catch(error => {
            if(error.response)
            {
                console.log("Server war nicht bereit!");
            }
            if(error.request)
            {
                console.log("Server nicht erreicht!");
                setTimeout(()=>{confirm_Result()}, [3000])
            }
            
        })
      }

      const confirm_Diagnosis = () =>{
        Axios.post(Api+'confirmDiagnosis', {schulerCode: schulerCode, schulerTicket: testTicket}).then((responseD) => {
            if(responseD.data.confirmation == "Roger Roger!")
            {
                set_End_Panel_Fill('End_Panel_Success');
                setEnd_Panel_EndButton('End_Panel_EndButton');
            }

            if(responseD.data.confirmation == "Something went wrong!")
            {
                DiagnoseAuswerten();
                setTimeout(()=>{confirm_Diagnosis()}, [20000])
            }

        })
        .catch(error => {
            if(error.response)
            {
                console.log("Server war nicht bereit!");
            }
            if(error.request)
            {
                console.log("Server nicht erreicht!");
                setTimeout(()=>{confirm_Diagnosis()}, [30000])
            }
            
        })
      }

  return (
    <div className="AiDe">
        <div className="AideL_Wrapper">
        <div className="Design" ><World Theme={Theme} Szene={Szene}></World></div>
    
            {/*Seite zur Dateneingabe*/ }
            <div className={DatenSeite}>
                <div className="DatenFeld">
                    <div className={LehrerCodeFeld}>Code der Lehrkraft: {userCode}</div>
                    <div className={SchulerCodeFeld}>{schulerCode}</div>
                    <div className={SchulerKlassenFeld}>{"Klasse: "+KlassenName+" (Kl. "+schulerKlasse+")"}</div>
                    
                    <div className={TestbogenFeld}>Testbogen: {testbogen}</div>
                    <div className={HandigkeitFeld}>Händigkeit:<div className={LinkshanderButtonaktiv} onClick={setLinkshander}>Links</div> <div className={RechtshanderButtonaktiv} onClick={setRechtshander}>Rechts</div></div>
                    <div className={VerbleibendeTestungenFeld}>Aktuelles Testticket: {testTicket}</div>
                    <div className={VersionsFeld}>Version: {version}</div>
                </div>
                <div className={LoginButton} onClick={LogIn}>Okay</div>
                <div className={StartButton} onClick={handleStart}>Start</div>
                <div className='ThemeChanger'></div>
                <div className='AudioTester' onClick={TestAudio}></div>
            </div>
            {/*Seite zur Dateneingabe*/ }
            <div className={AdminTool} onClick={setAdminToolPageVisible}></div>
            <div className={AdminToolPage}>
                <div className='ATP_Titel'>{"Diagnosedurchlauf von "+schulerCode}</div>
                <div className='ATP_Info'>{"Position ist: AiDe-Item "+(cycleCounter2+1) +" und Stimulus "+(stimulusCounter2+1)+"."}</div>
                <button className='ATP_Button1' onClick={phase4_tut1}>Gehe in Satzleseteil</button>
                <button className='ATP_Button1' onClick={DiagnoseBeenden}>Bisherige Ergebnisse senden</button>
                <button className='ATP_Button1' onClick={closeAdminToolPage}>Bisherige Ergebnisse speichern</button>
                <button className='ATP_Button2' onClick={closeAdminToolPage}>Schließen</button>
            </div>
            {/*Diagnoseumgebung*/ }
            <div className={DiagnoseSeite}>
            <div className={MetaSeite}>
                <div className={MetaSeite_Seite1}>
                    <div className='Meta_S1_Flaggenbereich'>
                        <div className={MetaS1_Deutsch} onClick={setSpracheDeutsch}></div>
                        <div className={MetaS1_Andere} onClick={setAndereSprache}></div>
                    </div>
                </div>
                <div className={MetaSeite_Seite2}>
                    <div className='Meta_S2_ReiheLinks'>
                        <div className={MetaS2_R1} onClick={handle_Englisch}><div className='MetaS2_R_Flag_GB' onClick={handle_Englisch}></div><div className='MetaS2_R_Label'>Englisch</div></div>
                        <div className={MetaS2_R2} onClick={handle_Franzosisch}><div className='MetaS2_R_Flag_Frankreich' onClick={handle_Franzosisch}></div><div className='MetaS2_R_Label'>Französisch</div></div>
                        <div className={MetaS2_R3} onClick={handle_Turkisch}><div className='MetaS2_R_Flag_Turkei' onClick={handle_Turkisch}></div><div className='MetaS2_R_Label'>Türkisch</div></div>
                        <div className={MetaS2_R4} onClick={handle_Arabisch}><div className='MetaS2_R_Flag_Arabisch' onClick={handle_Arabisch}></div><div className='MetaS2_R_Label'>Arabisch</div></div>
                    </div>
                    <div className='Meta_S2_ReiheRechts'>
                    <div className={MetaS2_R5} onClick={handle_Kurdisch}><div className='MetaS2_R_Flag_Kurdisch'  onClick={handle_Kurdisch}></div><div className='MetaS2_R_Label'>Kurdisch</div></div>
                    <div className={MetaS2_R6} onClick={handle_Ukrainisch}><div className='MetaS2_R_Flag_Ukrainisch' onClick={handle_Ukrainisch}></div><div className='MetaS2_R_Label'>Ukrainisch</div></div>
                    <div className={MetaS2_R7} onClick={handle_Russisch}><div className='MetaS2_R_Flag_Russisch' onClick={handle_Russisch}></div><div className='MetaS2_R_Label'>Russisch</div></div>
                    <div className={MetaS2_R8} onClick={handle_Andere}><div className='MetaS2_R_Flag_Andere' onClick={handle_Andere}></div><div className='MetaS2_R_Label'>Andere</div></div>
                    </div>
                </div>
                <div className={MetaSeite_Seite3}>
                    <div className='Meta_S3_Buttonbereich'>
                        <div className={MetaS3_B1} onClick={handle_LeseMedien0}><div className='MetaS3_B_pic0'></div><div className='MetaS3_B_Label'>keine</div></div>
                        <div className={MetaS3_B2} onClick={handle_LeseMedien1}><div className='MetaS3_B_pic1'></div><div className='MetaS3_B_Label'>wenige</div></div>
                        <div className={MetaS3_B3} onClick={handle_LeseMedien2}><div className='MetaS3_B_pic2'></div><div className='MetaS3_B_Label'>viele</div></div>
                        <div className={MetaS3_B4} onClick={handle_LeseMedien3}><div className='MetaS3_B_pic3'></div><div className='MetaS3_B_Label'>sehr viele</div></div>
                    </div>
                </div>
                <div className={MetaSeite_Seite4}>
                <div className='Meta_S4_Buttonbereich'>
                        <div className={MetaS4_B1} onClick={handle_Lesezeit0}><div className='MetaS4_B_pic0'></div><div className='MetaS3_B_Label'>gar nicht</div></div>
                        <div className={MetaS4_B2} onClick={handle_Lesezeit1}><div className='MetaS4_B_pic1'></div><div className='MetaS3_B_Label'>manchmal</div></div>
                        <div className={MetaS4_B3} onClick={handle_Lesezeit2}><div className='MetaS4_B_pic2'></div><div className='MetaS3_B_Label'>oft</div></div>
                        <div className={MetaS4_B4} onClick={handle_Lesezeit3}><div className='MetaS4_B_pic3'></div><div className='MetaS3_B_Label'>sehr oft</div></div>
                    </div>
                </div>
                <div className={MetaSeite_Seite5}>
                    <div className='Meta_S5_Buttonbereich'>
                        <div className={MetaS5_B1} onClick={handle_GenderFemale}><div className='MetaS5_B_pic0'></div><div className='MetaS3_B_Label'>Mädchen</div></div>
                        <div className={MetaS5_B2} onClick={handle_GenderMale}><div className='MetaS5_B_pic1'></div><div className='MetaS3_B_Label'>Junge</div></div>
                    </div>
                </div>
            </div>
                 <div className={TargetVisible}><TargetGroß World Theme={Theme} TargetText = {TargetText} TargetFont = {TargetFont} TargetType={TargetType}> </TargetGroß></div>
                 <div className='TutorWrapperTop' onClick={helfen}><Tutor3 Theme={Theme} ScreenImg={TutorScreen} ShowText={TutorText}></Tutor3></div> 
                 <div className={StimulusWrapper}><Stimulus Theme={Theme} StimulusText={StimulusText} StimulusType={TargetType}></Stimulus></div>
                 <div className={SatzItemLayout}>
                    <div className='SIL_Bildbereich'>
                        <div className={SIL_Bildbereich_BildFeld1} onClick={handleBild1Click}><div className={SIL1}></div></div>
                        <div className={SIL_Bildbereich_BildFeld2} onClick={handleBild2Click}><div className={SIL2}></div></div>
                        <div className={SIL_Bildbereich_BildFeld3} onClick={handleBild3Click}><div className={SIL3}></div></div>
                    </div>
                    <div className={SIL_Textbereich}>
                        <div className={SIL_Textbereich_Textfeld}>{SIL_Target}</div>
                    </div>
                 </div>
                 <div className={viewSpeaker}><div className={Speaker_work} onClick={audioHilfe}></div></div>
                 <div className={ButtonBereich}>
                     <div className={ButtonLinks} onClick={handleLinksClick} onContextMenu={(e)=> e.preventDefault()}><Button Theme={Theme} Type={ButtonLinksType}></Button></div>
                     <div className={ButtonRechts} onClick={handleRechtsClick} onContextMenu={(e)=> e.preventDefault()}><Button Theme={Theme} Type={ButtonRechtsType}></Button></div>
                 </div>
                 <div className={TargetKleinVisible}><TargetKlein Theme={Theme} TargetText={TargetText} TargetType={TargetType}></TargetKlein></div>
                 <div className={WeiterButton} onClick={handleWeiterClick} onContextMenu={(e)=> e.preventDefault()}><div className='WeiterButtonIcon'></div></div>
            </div>

            {/*Diagnoseumgebung*/ }
            {/*Progressbar*/ }
            <div className={progressBarVisible}>
                 {/*Progressbar  <div className="ProgressbarFill" style={{width:progress+"%"}}></div>Progressbar*/ }
                 <div className="ProgressbarFill" style={{width:progress+"%", backgroundColor:"rgb("+theDarkSide+","+theLightSide+","+25+")"}}></div>
            </div>
            <div className={AdminTool} onClick={setAdminToolPageVisible}></div>
            {/*Progressbar*/}
            <div className={ticketMonitor}>{"Version: "+version+" | Usercode: "+userCode+ " | Schüler-Code: "+schulerCode}</div>
            <div className={repeatButton}></div>  
            <div className='ToggleFullScreen' onClick={setFullScreen}></div>                    
            <div className={viewAbschlussSeite}>
                <div className='Kompass_LogoFeld'><div className='Kompass_Logo'></div></div>
                <div className={End_Panel}>
                    <div className='End_Panel_Titel'>{EndPanelText}</div>
                    <div className='End_Panel_Prgrss_Bar'>
                        <div className={End_Panel_Fill}></div>
                    </div>
                    <div className={End_Panel_EndButton} onClick={blackOut}>Beenden</div>
                </div>
                <div className={DemoPanel}>
                    <div className='End_Panel_Titel'>Demo Modus</div>
                    <div className='End_Panel_EndButton' onClick={blackOut}>Beenden</div>
                </div>
                
            </div> 


        </div>

    </div>
  )
}

export default AiDeL4